// // tonConnectSingleton.js
// import { TonConnectUI } from '@tonconnect/ui';

// let instance;

// export function getTonConnectUIInstance() {
//     if (!instance) {
//         instance = new TonConnectUI({
//             manifestUrl: 'https://www.tongifts.app/tonconnect-manifest.json',
//         });
//     }
//     return instance;
// }
// tonConnectInstance.js
import { TonConnectUI } from "@tonconnect/ui";

let tonConnectUIInstance = null;

export function getTonConnectUIInstance(options) {
    if (!tonConnectUIInstance) {
        tonConnectUIInstance = new TonConnectUI(options);
    }
    return tonConnectUIInstance;
}

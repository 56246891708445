<template>
    <Dropdown
        @optionOnclick="currentTokenSelected"
        :items="menuItems"
        :show-menu.sync="showMenu"
        :align="align"
    >
        <template #toggle-button>
            <button
                class="flex items-center gap-1"
                :class="{ 'gap-1': size === 'md', 'gap-0': size === 'sm' }"
            >
                <img
                    @error="
                        (e) =>
                            (e.target.src =
                                '/assets/imgs/utils/token/default.png')
                    "
                    :class="{
                        'h-[36px] w-[36px]': size === 'md',
                        'h-[20px] w-[20px]': size === 'sm',
                    }"
                    :src="`assets/imgs/utils/token/${currentTokenName}.png`"
                    alt="token-image"
                />
                <span
                    v-if="text"
                    class="whitespace-nowrap font-bold text-[#111]"
                    :class="{
                        'text-base': size === 'md',
                        'text-sm': size === 'sm',
                    }"
                >
                    {{ currentTokenName }}
                </span>
                <div
                    class="transition-all duration-300 ease-out"
                    :class="{ 'rotate-180': showMenu }"
                >
                    <svg-icon
                        type="mdi"
                        :path="mdiMenuDown"
                        :size="iconSize"
                        class="text-[#000]"
                    ></svg-icon>
                </div>
            </button>
        </template>
    </Dropdown>
</template>

<script>
import Dropdown from "@/components/Dropdown.vue";
import { mapGetters } from "vuex";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiMenuDown } from "@mdi/js";

export default {
    name: "TokenDropdown",
    props: {
        purpose: {
            type: String,
            default: "",
        },
        size: {
            type: String,
            default: "md",
        },
        text: {
            type: Boolean,
            default: false,
        },
        align: {
            type: String,
            default: "left",
        },
    },
    components: {
        Dropdown,
        SvgIcon,
    },
    data: () => ({
        mdiMenuDown,
        showMenu: false,
    }),
    computed: {
        ...mapGetters([
            "currentToken",
            "currentTokenName",
            "userInfo",
            "tokenList",
        ]),
        iconSize() {
            if (this.size === "md") return 32;
            if (this.size === "sm") return 20;
        },
        menuItems() {
            let currencyTokens = this.tokenList;

            if (this.purpose === "deposit") {
                currencyTokens = currencyTokens.filter(
                    (token) => token.deposit,
                );
            }
            if (this.purpose === "withdraw") {
                currencyTokens = currencyTokens.filter((token) => {
                    const cantWithdraw =
                        token.cantWithdraw ||
                        !token.withdraw ||
                        // 沒有 transfer 方法 也沒有 tonwallet 方法
                        // 沒有 transfer 方法
                        (!(
                            token.withdraw &&
                            token.withdraw.methods &&
                            token.withdraw.methods["TRANSFER"]
                        ) &&
                            // 沒有 tonwallet 方法
                            !(
                                token.withdraw &&
                                token.withdraw.methods &&
                                token.withdraw.methods["TONWALLET"]
                            ));
                    return !cantWithdraw;
                });
            }
            if (this.purpose === "send") {
                currencyTokens = currencyTokens.filter(
                    (token) => !token.cantSend,
                );
            }

            return currencyTokens.map((token) => {
                return {
                    name: token._id,
                    imgPath: `assets/imgs/utils/token/${token._id}.png`,
                    text: token._id,
                };
            });
        },
    },
    methods: {
        currentTokenSelected(tokenOption) {
            const tokenName = tokenOption.name;
            const targetToken =
                this.tokenList &&
                this.tokenList.find((token) => token._id === tokenName);
            if (targetToken) {
                console.log("set token");
                this.$store.commit("setCurrentToken", targetToken);
            }
        },
    },
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"absolute left-0 top-0 h-[calc(100%-48px)] w-full px-6 pb-20 pt-4 opacity-0 transition-all duration-300",class:{ '!opacity-100': _vm.isShowing }},[_c('div',{staticClass:"absolute left-0 top-0 z-0 h-[100px] w-full transform-gpu bg-gradient-to-b from-[rgba(0,0,0,.25)] to-transparent opacity-100 transition-all duration-300",class:{ '!opacity-0': _vm.isTasksFinished }}),_c('div',{staticClass:"relative z-10 h-full w-full overflow-hidden"},[_c('div',{staticClass:"absolute left-0 z-10 flex h-full w-full translate-y-0 transform-gpu flex-col gap-4 opacity-100 transition-all duration-300",class:{ '!z-0 !-translate-y-5 !opacity-0': _vm.isTasksFinished }},[_c('div',{staticClass:"flex w-full items-center justify-end"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.receiverAmount),expression:"receiverAmount"}],staticClass:"flex flex-grow items-center gap-2"},[_c('div',{staticClass:"mr-3 flex items-center"},_vm._l((_vm.firstThreeGiftReceiverRecords),function(record){return _c('div',{key:record._id,staticClass:"-mr-3 h-8 w-8 min-w-8 rounded-full border border-[#fff]"},[(record.profilePhoto)?_c('img',{staticClass:"h-full w-full rounded-full",attrs:{"src":record.profilePhoto,"alt":""},on:{"error":(e) =>
                                        (e.target.src =
                                            '/assets/imgs/utils/avatar/avatar_default.svg')}}):_c('img',{staticClass:"h-full w-full rounded-full",attrs:{"src":"/assets/imgs/utils/avatar/avatar_default.svg","alt":""}})])}),0),_c('span',{staticClass:"whitespace-pre-line text-xs text-[#fff]",domProps:{"innerHTML":_vm._s(
                            _vm.$t(
                                'receiveGifts.giftCover.tasks.receiversTitleText',
                                { receiverAmount: _vm.receiverAmount },
                            )
                        )}})]),_c('button',{staticClass:"flex h-8 w-8 items-center justify-center rounded-full bg-[#fff] bg-opacity-60",attrs:{"disabled":_vm.isLoading},on:{"click":_vm.refreshBtnOnclick}},[_c('svg-icon',{staticClass:"h-5 w-5 text-center text-2xl font-bold text-[#FF5D8F]",class:{ '!animate-spin': _vm.isLoading },attrs:{"type":"mdi","path":_vm.mdiRefresh}})],1)]),_c('div',{staticClass:"no-scrollbar w-full flex-grow overflow-scroll"},[(_vm.isLoading && !_vm.tasks.length)?_c('div',{staticClass:"flex w-full flex-col gap-4"},_vm._l((4),function(i){return _c('div',{key:i,staticClass:"h-16 w-full animate-pulse rounded-2xl bg-[#fff]"})}),0):_c('div',{staticClass:"flex w-full flex-col gap-4"},_vm._l((_vm.tasks),function(task){return _c('div',{key:task._id,staticClass:"flex min-h-16 w-full items-center gap-3 rounded-2xl bg-[#fff] px-4 py-3",on:{"click":() => _vm.taskBgOnClick(task)}},[_c('div',{staticClass:"flex h-10 w-10 min-w-10 items-center justify-center rounded-full",class:{
                                'bg-[#FFE5ED]': !task.status,
                                'bg-[#E3FFE9]': task.status,
                            }},[_c('svg-icon',{staticClass:"h-5 w-5 text-center text-2xl font-bold",class:{
                                    'text-[#FF5D8F]': !task.status,
                                    'text-[#00DD31]': task.status,
                                },attrs:{"type":"mdi","path":task.status ? _vm.mdiCheck : _vm.mdiLockOutline}})],1),_c('div',{staticClass:"flex flex-grow flex-col"},[_c('span',{staticClass:"text-base font-bold text-[#111]"},[_vm._v(_vm._s((_vm.currentLocale && task && task.language && task.language[ `title-${_vm.currentLocale}` ]) || task.title))]),_c('span',{staticClass:"text-xs text-[#aaa]"},[_vm._v(_vm._s((_vm.currentLocale && task && task.language && task.language[ `description-${_vm.currentLocale}` ]) || task.description))])]),(
                                !task.status &&
                                task.type === 'checkin' &&
                                !_vm.isCheckinPending
                            )?_c('div',{staticClass:"flex items-center gap-[6px]"},[_c('button',{staticClass:"flex h-8 min-w-20 flex-nowrap items-center justify-center gap-1 rounded-lg bg-[#0088CC] px-2",class:{
                                    'opacity-30': _vm.isLoading,
                                    'w-8 min-w-8':
                                        _vm.currentConnectedWalletAddress,
                                },on:{"click":function($event){$event.stopPropagation();return (() => _vm.taskBtnOnClick(task)).apply(null, arguments)}}},[_c('img',{staticClass:"h-4 w-4 min-w-4",attrs:{"src":"assets/imgs/views/receiveGifts/logo_ton.svg","alt":""}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.currentConnectedWalletAddress),expression:"!currentConnectedWalletAddress"}],staticClass:"text-center text-xs font-bold text-[#fff]"},[_vm._v(_vm._s(_vm.taskBtnText(task)))])]),_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentConnectedWalletAddress),expression:"currentConnectedWalletAddress"}],staticClass:"h-8 min-w-20 rounded-lg bg-[#FFC90C] px-2 text-center text-sm font-bold text-[#fff]",class:{ 'opacity-30': _vm.isLoading },attrs:{"disabled":_vm.isLoading},on:{"click":_vm.checkInBtnOnClick}},[_vm._v(" "+_vm._s(_vm.taskBtnText(task))+" ")])]):_vm._e(),(
                                !task.status &&
                                task.type === 'checkin' &&
                                _vm.isCheckinPending
                            )?_c('img',{staticClass:"h-5 w-5 min-w-5 animate-spin",attrs:{"src":"assets/imgs/views/receiveGifts/small_loading_icon.svg","alt":""}}):_vm._e(),(
                                !task.status &&
                                task.type !== 'checkin' &&
                                !(
                                    task.type === 'thirdPartyTask' &&
                                    (!task.link || !task.link.length)
                                )
                            )?_c('button',{staticClass:"h-8 min-w-20 rounded-lg bg-[#FFC90C] px-2 text-center text-sm font-bold text-[#fff]",class:{ 'opacity-30': _vm.isLoading },attrs:{"disabled":_vm.isLoading}},[_vm._v(" "+_vm._s(_vm.taskBtnText(task))+" ")]):_vm._e(),(
                                task.type === 'thirdPartyTask' &&
                                (!task.link || !task.link.length)
                            )?_c('img',{staticClass:"h-5 w-5 min-w-5 animate-spin",attrs:{"src":"assets/imgs/views/receiveGifts/small_loading_icon.svg","alt":""}}):_vm._e()])}),0)])]),_c('div',{staticClass:"absolute left-0 z-0 flex h-full w-full translate-y-5 flex-col items-center justify-center gap-4 bg-[radial-gradient(50%_50%_at_50%_50%,rgba(0,0,0,0.16)_0%,rgba(0,0,0,0)_100%)] opacity-0 transition-all delay-500 duration-300",class:{
                '!z-10 !translate-y-0 !opacity-100': _vm.isTasksFinished,
            }},[_c('span',{staticClass:"text-center text-2xl font-bold text-[#fff]"},[_vm._v(" "+_vm._s(_vm.$t("receiveGifts.giftCover.tasks.completedTitleText"))+" ")]),_c('span',{staticClass:"text-center text-base text-[#fff]"},[_vm._v(" "+_vm._s(_vm.$t( "receiveGifts.giftCover.tasks.completedDescriptionText1", ))),_c('br'),_vm._v(_vm._s(_vm.$t( "receiveGifts.giftCover.tasks.completedDescriptionText2", ))+" ")]),_c('svg-icon',{staticClass:"h-6 w-6 animate-bounce text-center text-2xl font-bold text-[#fff]",attrs:{"type":"mdi","path":_vm.mdiChevronDoubleDown}})],1)]),_c('Toast',{ref:"toast"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div class="flex w-full flex-col items-center gap-10 pt-8">
        <!-- logo -->
        <img
            src="assets/imgs/utils/logo/ton_gifts_logo.svg"
            alt=""
            class="h-[68px]"
        />
        <!-- main text wrapper -->
        <div class="flex w-full flex-col items-center gap-4">
            <!-- order info -->
            <div class="flex w-full flex-col items-center gap-2">
                <!-- third party name -->
                <span class="text-center text-xl font-bold text-[#000]">{{
                    $t("thirdPartyOrder.transferOutText", {
                        thirdPartyName,
                    })
                }}</span>
                <!-- order id -->
                <span class="text-center text-sm text-[#6f6f6f]">{{
                    $t("thirdPartyOrder.orderIdText", {
                        orderId,
                    })
                }}</span>
            </div>
            <!-- order amount -->
            <div class="flex items-center gap-1">
                <img
                    @error="
                        (e) =>
                            (e.target.src =
                                '/assets/imgs/utils/token/default.png')
                    "
                    :src="`assets/imgs/utils/token/${orderTokenName}.png`"
                    alt="token-image"
                    class="h-9 w-9"
                />
                <span class="text-[40px] font-bold text-[#FFC90C]">{{
                    orderAmount
                }}</span>
                <span
                    class="flex h-10 items-end text-base font-bold text-[#FFC90C]"
                    >{{ orderTokenName }}</span
                >
            </div>
        </div>
        <!-- confirm button -->
        <button
            @click="confirmOrderOnClick"
            :disabled="isConfirming"
            class="flex h-14 w-full items-center justify-center rounded-lg bg-[#FF5D8F] text-center text-lg font-bold text-[#fff]"
            :class="{ 'opacity-30': isConfirming }"
        >
            {{ $t("utils.confirm") }}
        </button>
    </div>
</template>

<script>
export default {
    name: "OrderPending",
    props: {
        thirdPartyName: {
            type: String,
            default: "",
        },
        orderId: {
            type: String,
            default: "",
        },
        orderTokenName: {
            type: String,
            default: "",
        },
        orderAmount: {
            type: String,
            default: "0",
        },
        isConfirming: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        confirmOrderOnClick() {
            this.$emit("confirmOrderOnClick");
        },
    },
};
</script>

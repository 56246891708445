<template>
    <div>
        <!-- connect panel -->
        <WalletConnectPanel
            ref="walletConnectPanel"
            :is-wallet-connect-panel-active.sync="isWalletConnectPanelActive"
        />
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import WalletConnectPanel from "./components/WalletConnectPanel.vue";

export default {
    name: "WalletConnector",
    components: {
        WalletConnectPanel,
    },
    data: () => ({
        isWalletConnectPanelActive: false,
    }),
    computed: {
        ...mapGetters(["currentConnectedWalletAddress"]),
    },
    methods: {
        // 拉起panel
        handleSwitchWallet() {
            this.isWalletConnectPanelActive = true;
        },
        // 連接錢包
        handleConnectWallet(cb = null) {
            this.$refs.walletConnectPanel.connectWallet(cb);
        },
        // 斷開錢包
        handleDisconnectWallet(cb = null) {
            this.$refs.walletConnectPanel.disconnectWallet(cb);
        },
        // 發起簽到簽名
        handleDailyCheckInWallet(cb = null) {
            this.$refs.walletConnectPanel.dailyCheckIn(cb);
        },
        // 發起任務簽名
        handleTaskCheckInWallet(cb = null) {
            this.$refs.walletConnectPanel.checkIn(cb);
        },
        // 發起充值簽名
        handleDepositFromWallet(message, cb = null) {
            this.$refs.walletConnectPanel.deposit(message, cb);
        },
        // 發起提現簽名
        handleWithdrawToWallet(message, cb = null) {
            this.$refs.WalletConnectPanel.withdraw(message, cb);
        },
    },
    created() {
        // 註冊監聽所有事件
        // 拉起panel
        this.$bus.$on("switchWallet", this.handleSwitchWallet);
        // 連接錢包
        this.$bus.$on("connectWallet", this.handleConnectWallet);
        // 斷開錢包
        this.$bus.$on("disconnectWallet", this.handleDisconnectWallet);
        // 發起簽到簽名
        this.$bus.$on("dailyCheckInWallet", this.handleDailyCheckInWallet);
        // 發起任務簽名
        this.$bus.$on("taskCheckInWallet", this.handleTaskCheckInWallet);
        // 發起充值簽名
        this.$bus.$on("depositFromWallet", this.handleDepositFromWallet);
        // 發起提現簽名
        this.$bus.$on("withdrawToWallet", this.handleWithdrawToWallet);
    },
};
</script>

<template>
    <div class="flex w-full flex-col gap-3 rounded-2xl bg-white p-4">
        <div class="flex items-center justify-between">
            <span class="text-lg font-bold text-[#111111]"
                >Essential for web3</span
            >
            <button
                class="flex items-center text-sm capitalize text-[#9699b4]"
                @click="showRecommendedBox"
            >
                View All

                <svg-icon
                    type="mdi"
                    :path="mdiChevronRight"
                    :size="20"
                    class="-mr-1 text-[#9699b4]"
                ></svg-icon>
            </button>
        </div>
        <!-- Items -->
        <EssentialItem
            v-if="renderItems().length"
            v-for="(item, index) in renderItems()"
            :key="index"
            :logo="item.logo"
            :appName="item.appName"
            :keyword="item.keyword"
            :detailLink="item.detailLink"
            :rewards="item.rewards"
            :miniappLink="item.miniappLink"
        />
        <RecommendedBox ref="recommendedBox" />
    </div>
</template>

<script>
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiChevronRight } from "@mdi/js";
import EssentialItem from "./EssentialItem.vue";
import RecommendedBox from "./RecommendedBox.vue";

export default {
    name: "Essential",
    components: {
        SvgIcon,
        EssentialItem,
        RecommendedBox,
    },
    data: () => ({
        visible: false,
        mdiChevronRight,
    }),
    methods: {
        showRecommendedBox() {
            this.$refs.recommendedBox.show();
        },
        renderItems() {
            return [
                {
                    logo: "assets/imgs/utils/covers/cover_newdefault.png",
                    appName: "TonGifts",
                    keyword: "miniapp keyword",
                    detailLink: "/detail",
                    miniappLink: "#",
                    rewards: [
                        {
                            img: "HIT.png",
                            amount: "300",
                            token: "HIT",
                        },
                        {
                            img: "GFT.png",
                            amount: "300",
                            token: "GFT",
                        },
                    ],
                },
                {
                    logo: "assets/imgs/utils/covers/cover_newdefault.png",
                    appName: "TonGifts",
                    keyword: "miniapp keyword",
                    detailLink: "/detail",
                    miniappLink: "#",
                    rewards: [
                        {
                            img: "HIT.png",
                            amount: "300",
                            token: "HIT",
                        },
                        {
                            img: "GFT.png",
                            amount: "300",
                            token: "GFT",
                        },
                    ],
                },
                {
                    logo: "assets/imgs/utils/covers/cover_newdefault.png",
                    appName: "TonGifts",
                    keyword: "miniapp keyword",
                    detailLink: "/detail",
                    miniappLink: "#",
                    rewards: [
                        {
                            img: "HIT.png",
                            amount: "300",
                            token: "HIT",
                        },
                        {
                            img: "GFT.png",
                            amount: "300",
                            token: "GFT",
                        },
                    ],
                },
            ];
        },
    },
};
</script>

<style lang="scss" scoped></style>

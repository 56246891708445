<template>
    <div>
        <div
            v-show="isNotifierActive"
            class="fixed left-0 top-0 z-50 flex h-screen w-screen flex-col items-center justify-between bg-gradient-to-br from-[#FFE7D1] to-[#FFB1DB] pt-16"
        >
            <!-- content -->
            <div
                class="flex translate-y-1 flex-col gap-4 opacity-0 transition-all delay-100 duration-300"
                :class="{ '!translate-y-0 !opacity-100': displayContent }"
            >
                <!-- today continuous -->
                <span
                    class="animate-pulse text-center text-[80px] font-bold text-[#FF5D8F]"
                    >{{ countdownNumber }}</span
                >
                <div class="flex w-full flex-col items-center gap-2">
                    <span class="text-center text-2xl font-bold text-[#111]">{{
                        $t("components.dailyCheckInNotifier.descriptionText", {
                            currentProgress: currentContinuousDays + 1,
                        })
                    }}</span>
                    <span
                        class="text-center text-sm font-bold text-[#FF5D8F]"
                        >{{
                            $t("components.dailyCheckInNotifier.hintText", {
                                tokenName: currentCreditsTokenName,
                            })
                        }}</span
                    >
                </div>
            </div>
            <!-- claim button -->
            <div class="w-full px-6 py-4">
                <button
                    @click="goDailyCheckin"
                    class="h-14 min-h-14 w-full rounded-lg bg-[#FF5D8F] text-center text-lg font-bold normal-case text-[#fff]"
                >
                    {{ $t("components.dailyCheckInNotifier.claimBtnText") }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import userAPI from "@/apis/user";

export default {
    name: "DailyCheckInNotifier",
    data: () => ({
        isNotifierActive: false,
        displayContent: false,
        records: null,
        countdownNumber: 5,
        countdownTimer: null,
    }),
    computed: {
        ...mapGetters(["currentCreditsTokenName"]),
        // 根據不同天的 id 分組的紀錄 (過濾掉了 pending 中的紀錄)
        dailyGroupedRecords() {
            const records = this.records;
            return records.reduce((acc, currentRecord) => {
                // 過濾掉沒有完成的紀錄
                if (currentRecord.status !== 1) {
                    return acc;
                }
                // 尋找id 相同並且 utc 時間同一天的紀錄
                const sameDateSameIdItem = acc.find((accItem) => {
                    const accItemConfigId = accItem.id;
                    // 取整合後的物件當中的任一紀錄
                    const accItemExistRecord =
                        accItem["default"] || accItem["onChain"];
                    const accItemExistRecordTime =
                        accItemExistRecord &&
                        accItemExistRecord.createdAt &&
                        moment.utc(accItemExistRecord.createdAt);
                    const currentItemTime =
                        currentRecord &&
                        currentRecord.createdAt &&
                        moment.utc(currentRecord.createdAt);
                    const currentItemConfigId =
                        currentRecord.data &&
                        currentRecord.data.config &&
                        currentRecord.data.config._id;
                    return (
                        accItemConfigId === currentItemConfigId &&
                        accItemExistRecordTime.isSame(currentItemTime, "day")
                    );
                });
                // 如果找到了相同id 又相同utc日期的紀錄，就加進去
                if (sameDateSameIdItem) {
                    sameDateSameIdItem[currentRecord.type] = currentRecord;
                    // 沒有的話就新增一個單一紀錄分組
                } else {
                    const newItem = {
                        id:
                            currentRecord.data &&
                            currentRecord.data.config &&
                            currentRecord.data.config._id,
                    };
                    newItem[currentRecord.type] = currentRecord;
                    acc.push(newItem);
                }
                return acc;
            }, []);
        },
        // 到當天為止有效的連續簽到紀錄
        validContinuousDailyGroupedRecords() {
            // 如果根本沒有紀錄就返回空陣列
            if (!this.records || !this.records.length) return [];
            // 如果最新一次紀錄不在昨天以後，表示連續簽到已經中斷了
            const latestRecord = this.records[this.records.length - 1];
            const latestRecordTime =
                latestRecord &&
                latestRecord.createdAt &&
                moment(latestRecord.createdAt);
            const lastDayStartTime = moment()
                .utc()
                .startOf("day")
                .add(-1, "day");
            const isContinuous = latestRecordTime > lastDayStartTime;
            const continuousDays = latestRecord.streak;
            //  如果昨天有連續簽到紀錄，根據昨天的連續簽到紀錄 streak 取最後有效的幾組紀錄
            return isContinuous
                ? this.dailyGroupedRecords.slice(-1 * continuousDays)
                : [];
        },
        // 當天錢包簽到是否已經完成
        isTodayOnChainCheckInCompleted() {
            // 沒有紀錄
            if (!this.validContinuousDailyGroupedRecords.length) return false;
            const latestValidContinuousDailyGroupedRecords =
                this.validContinuousDailyGroupedRecords &&
                this.validContinuousDailyGroupedRecords[
                    this.validContinuousDailyGroupedRecords.length - 1
                ];
            const latestValidContinuousDailyRecord =
                (latestValidContinuousDailyGroupedRecords &&
                    latestValidContinuousDailyGroupedRecords.default) ||
                latestValidContinuousDailyGroupedRecords.onChain;
            const latestValidContinuousDailyRecordTime =
                latestValidContinuousDailyRecord &&
                moment(latestValidContinuousDailyRecord.createdAt);
            const todayStartTime = moment().utc().startOf("day");
            return (
                latestValidContinuousDailyRecordTime &&
                latestValidContinuousDailyRecordTime > todayStartTime
            );
        },
        currentContinuousDays() {
            return (
                this.validContinuousDailyGroupedRecords &&
                this.validContinuousDailyGroupedRecords.length
            );
        },
    },
    watch: {
        isNotifierActive(newVal) {
            if (newVal) {
                setTimeout(() => {
                    this.displayContent = true;
                    this.startCountdown();
                }, 100);
            }
        },
        countdownNumber(number) {
            if (number < 1) {
                clearInterval(this.countdownTimer);
                this.isNotifierActive = false;
            }
        },
    },
    methods: {
        async getCheckinProgress() {
            try {
                const res = await userAPI.getCheckinProgress();
                if (res.status !== 200) throw new Error();
                const reversedRecords = res.data;
                const records = reversedRecords.reverse();
                this.records = records;
                this.checkIsTodayOnChainCheckInCompleted();
            } catch (err) {
                console.log("getCheckinProgress err: ", err);
            }
        },
        checkIsTodayOnChainCheckInCompleted() {
            if (!this.isTodayOnChainCheckInCompleted) {
                this.isNotifierActive = true;
            }
        },
        startCountdown() {
            this.countdownTimer = setInterval(() => {
                this.countdownNumber -= 1;
            }, 1000);
        },
        goDailyCheckin() {
            if (this.$route.name !== "daily-check-in") {
                this.$router.push({ name: "daily-check-in" });
            }
            clearInterval(this.countdownTimer);
            this.isNotifierActive = false;
        },
    },
    created() {
        const currentRouteName = this.$route.name;
        // 如果是開紅包時，就不要跳出
        if (currentRouteName === "receive-gifts") return;
        if (currentRouteName === "promotion") return;
        this.getCheckinProgress();
    },
    beforeDestroy() {
        clearInterval(this.countdownTimer);
    },
};
</script>

<template>
    <transition name="fade">
        <div
            v-if="isActive"
            class="fixed left-0 top-0 z-10 h-screen w-screen p-6"
        >
            <!-- Overlay -->
            <div
                class="absolute left-0 top-0 z-[-1] h-full w-full bg-[#000] bg-opacity-60"
                @click="close"
            ></div>
            <!-- Panel -->
            <div
                class="relative left-0 top-0 flex h-full w-full rounded-xl bg-gradient-to-t from-[#FFE5ED] to-[#fff]"
            >
                <!-- background image -->
                <img
                    src="assets/imgs/views/receiveGifts/invitationPanel_qrCodeBg.svg"
                    alt=""
                    class="absolute left-0 top-0 z-0 w-full pt-10"
                />
                <!-- panel content -->
                <div class="relative z-10 flex w-full flex-col items-center">
                    <!-- hint -->
                    <div
                        class="flex w-full justify-center bg-[#FF5D8F] bg-opacity-15 p-2"
                    >
                        <span class="text-center text-sm text-[#FF5D8F]">{{
                            $t("receiveGifts.qrCodeModal.screenshotHintText")
                        }}</span>
                    </div>
                    <!-- sender description wrapper -->
                    <div class="mt-6 flex flex-col items-center gap-2">
                        <img
                            @error="
                                (e) =>
                                    (e.target.src =
                                        '/assets/imgs/utils/avatar/avatar_default.svg')
                            "
                            :src="
                                currentUserProfilePicUrl ||
                                '/assets/imgs/utils/avatar/avatar_default.svg'
                            "
                            alt="user_profile_picture"
                            class="h-10 w-10 rounded-full"
                        />
                        <span
                            class="text-center text-base font-bold text-[#111]"
                            >{{
                                $t("receiveGifts.qrCodeModal.giftTitleText", {
                                    sender: currentUserName,
                                })
                            }}</span
                        >
                    </div>
                    <!-- gift wrapper -->
                    <div
                        class="relative mt-5 flex w-full max-w-[240px] flex-grow flex-col items-center overflow-hidden rounded-[20px]"
                        :style="{ backgroundColor: giftBgColor }"
                    >
                        <!-- gift cover -->
                        <img
                            :src="`https://www.tongifts.app/static/tg_gift_img/cover_${isForNewUser ? 'newdefault' : giftCoverId}.png`"
                            alt=""
                            class="relative h-[95%] w-full rounded-t-3xl object-cover object-bottom pb-10"
                            :style="{
                                filter: 'drop-shadow(0px 4px 16px rgba(0,0,0,.16))',
                            }"
                        />
                        <!-- qr code wrapper -->
                        <div
                            class="relative bottom-[100px] flex h-[100px] w-[100px] items-center justify-center"
                        >
                            <!-- qr code -->
                            <vue-qr
                                :text="targetAddress"
                                :margin="6"
                                :size="112"
                            ></vue-qr>
                            <!-- logo -->
                            <div
                                class="absolute left-1/2 top-1/2 flex h-5 w-5 -translate-x-1/2 -translate-y-1/2 items-center justify-center bg-[#fff]"
                            >
                                <img
                                    src="assets/imgs/utils/logo/ton_gifts_logo.svg"
                                    alt=""
                                    class="w-[14px]"
                                />
                            </div>
                        </div>
                    </div>
                    <!-- promotion text -->
                    <span class="mt-6 px-6 text-center text-sm text-[#777]">{{
                        $t("receiveGifts.qrCodeModal.tgPromotionText", {
                            botName: TGBotName,
                        })
                    }}</span>
                    <!-- TG tag -->
                    <div
                        class="mb-6 mt-4 flex items-center gap-3 rounded-lg bg-[#30A3E6] py-1 pl-3 pr-1"
                    >
                        <span class="text-sm text-[#fff]">{{
                            $t("receiveGifts.qrCodeModal.onTelegram")
                        }}</span>
                        <div
                            class="flex items-center gap-1 rounded bg-[#fff] px-2 py-[2px]"
                        >
                            <img
                                src="assets/imgs/icons/invitationPanel_search.svg"
                                alt=""
                                class="h-4 w-4"
                            />
                            <span class="text-xs font-bold text-[#111]">{{
                                TGBotName
                            }}</span>
                        </div>
                    </div>
                </div>
                <!-- Close Button -->
                <button
                    @click="close"
                    class="absolute bottom-0 left-1/2 z-10 flex h-6 w-6 -translate-x-1/2 translate-y-1/2 items-center justify-center rounded-full bg-[#fff] text-base text-[#aaa]"
                >
                    &times;
                </button>
            </div>
        </div>
    </transition>
</template>

<script>
import { mapGetters } from "vuex";
import VueQr from "vue-qr";

export default {
    name: "InvitationQRCodeModal",
    components: {
        VueQr,
    },
    props: {
        isActive: {
            type: Boolean,
            default: false,
        },
        giftInfo: {
            type: Object,
            default: null,
        },
        isForNewUser: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters(["userInfo"]),
        currentUserName() {
            return (
                (this.userInfo &&
                    this.userInfo.user &&
                    this.userInfo.user.tg_info &&
                    this.userInfo.user.tg_info.first_name) ||
                ""
            );
        },
        currentUserProfilePicUrl() {
            return (
                (this.userInfo &&
                    this.userInfo.user &&
                    this.userInfo.user.profilePhoto) ||
                null
            );
        },
        giftId() {
            return this.giftInfo && this.giftInfo._id;
        },
        userId() {
            return (
                this.userInfo && this.userInfo.info && this.userInfo.info._id
            );
        },
        userInviteLink() {
            return (
                this.userInfo &&
                this.userInfo.user &&
                this.userInfo.user.inviteLink
            );
        },
        targetAddress() {
            return this.isForNewUser
                ? this.userInviteLink
                : `${process.env.VUE_APP_BOT_URL}/${process.env.VUE_APP_MINIAPP}?startapp=o_${this.giftId}_${this.userId}`;
        },
        giftCoverId() {
            return (
                (this.giftInfo &&
                    this.giftInfo.info &&
                    this.giftInfo.info.coverId) ||
                "newdefault"
            );
        },
        giftBgColor() {
            return (
                (this.giftInfo &&
                    this.giftInfo.info &&
                    this.giftInfo.info.gift &&
                    this.giftInfo.info.gift.theme &&
                    this.giftInfo.info.gift.theme.coverBgColor) ||
                "#3A1691"
            );
        },
        TGBotName() {
            return process.env.VUE_APP_BOT_NAME;
        },
    },
    // watch: {
    //     isActive(newVal, preVal) {
    //         if (newVal && !preVal) { // 開啟時
    //             this.updateUserInfo()
    //         }
    //     }
    // },
    methods: {
        close() {
            this.$emit("close");
        },
        // async updateUserInfo() {
        //     try {
        //         const err = await this.$store.dispatch("updateUserInfo");
        //         if (err) {
        //             throw new Error(JSON.stringify(err));
        //         }
        //     } catch (err) {
        //         window.Telegram.WebApp.showAlert(err);
        //     }
        // },
    },
};
</script>

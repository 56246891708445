var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Dropdown',{attrs:{"items":_vm.menuItems,"show-menu":_vm.showMenu,"align":_vm.align},on:{"optionOnclick":_vm.currentTokenSelected,"update:showMenu":function($event){_vm.showMenu=$event},"update:show-menu":function($event){_vm.showMenu=$event}},scopedSlots:_vm._u([{key:"toggle-button",fn:function(){return [_c('button',{staticClass:"flex items-center gap-1",class:{ 'gap-1': _vm.size === 'md', 'gap-0': _vm.size === 'sm' }},[_c('img',{class:{
                    'h-[36px] w-[36px]': _vm.size === 'md',
                    'h-[20px] w-[20px]': _vm.size === 'sm',
                },attrs:{"src":`assets/imgs/utils/token/${_vm.currentTokenName}.png`,"alt":"token-image"},on:{"error":(e) =>
                        (e.target.src =
                            '/assets/imgs/utils/token/default.png')}}),(_vm.text)?_c('span',{staticClass:"whitespace-nowrap font-bold text-[#111]",class:{
                    'text-base': _vm.size === 'md',
                    'text-sm': _vm.size === 'sm',
                }},[_vm._v(" "+_vm._s(_vm.currentTokenName)+" ")]):_vm._e(),_c('div',{staticClass:"transition-all duration-300 ease-out",class:{ 'rotate-180': _vm.showMenu }},[_c('svg-icon',{staticClass:"text-[#000]",attrs:{"type":"mdi","path":_vm.mdiMenuDown,"size":_vm.iconSize}})],1)])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <transition name="fade">
        <div v-if="show" class="toast" :class="iconClass">
            <div class="toast-icon" v-if="iconType && `${iconType}`.length">
                <a-icon
                    v-if="iconType === 'success'"
                    type="check-circle"
                    theme="filled"
                />
                <a-icon
                    v-if="iconType === 'error'"
                    type="close-circle"
                    theme="filled"
                />
                <a-icon
                    v-if="iconType === 'warning'"
                    type="exclamation-circle"
                    theme="filled"
                />
            </div>
            <div class="flex flex-col items-center gap-2">
                <span
                    v-if="title && `${title}`.length"
                    class="text-center text-lg text-[#fff]"
                    >{{ title }}</span
                >
                <span
                    v-if="message && `message`.length"
                    class="text-center text-base text-[#fff]"
                >
                    {{ message }}
                </span>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: "Toast",

    data() {
        return {
            show: false,
            iconType: "",
            title: "",
            message: "",
        };
    },
    created() {
        this.$bus.$on("show-toast", this.showToast);
    },
    beforeDestroy() {
        this.$bus.$off("show-toast", this.showToast); // 清理事件监听器
    },
    computed: {
        iconClass() {
            return {
                "toast-success": this.iconType === "success",
                "toast-error": this.iconType === "error",
                "toast-warning": this.iconType === "warning",
            };
        },
    },
    methods: {
        showToast({ type = "", message = "", title = "" }) {
            this.iconType = type;
            this.title = title;
            this.message = message;
            this.show = true;
            setTimeout(() => {
                this.show = false;
            }, 3000); // 3秒后淡出消失
        },
    },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active in <2.1.8 */ {
    opacity: 0;
}

.toast-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    /* Icon容器大小 */
    height: 50px;
    /* Icon容器大小 */
    margin-bottom: 8px;
    /* 图标与文本的间距 */
    border-radius: 50%;
    /* 圆形背景 */
    /* background-color: #fff; */
    /* 灰色背景 */
}

.toast-icon .anticon {
    font-size: 40px;
    /* 图标大小 */
    color: #fff;
    /* 图标颜色 */
}

.toast {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 320px;
    max-width: 320px;
    padding: 20px;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.64); /* 背景颜色设置为70%的透明度 */
    color: #fff;
    text-align: center;
    z-index: 1000;
}
</style>

<template>
    <div
        @click.stop="$router.push(detailLink)"
        class="relative h-40 cursor-pointer rounded-2xl bg-[url('/public/assets/imgs/views/discover/app-cover.png')] bg-cover bg-center bg-no-repeat"
    >
        <div
            @click.stop
            class="relative top-full flex -translate-y-full cursor-default items-center justify-between rounded-bl-2xl rounded-br-2xl bg-black/20 px-4 py-3 backdrop-blur-sm"
        >
            <div class="flex flex-1 items-center gap-4">
                <!-- Img -->
                <div class="size-10 rounded-lg bg-black p-1.5">
                    <img
                        class="m-auto size-7"
                        :src="logo"
                        @error="
                            (e) =>
                                (e.target.src =
                                    '/assets/imgs/utils/token/default.png')
                        "
                    />
                </div>
                <!-- Title & Description -->
                <div
                    class="inline-flex h-8 max-w-36 flex-col justify-center gap-0.5 truncate text-white"
                >
                    <span class="text-sm font-bold">{{ title }}</span>
                    <span class="text-xs">{{ description }}</span>
                </div>
            </div>
            <!-- Open btn -->
            <button
                @click.stop="$router.push(miniappLink)"
                class="h-8 basis-1/5 rounded-lg bg-[#ff5c8e] px-4 text-sm font-bold leading-[1.875rem] text-white"
            >
                {{ $t("utils.open") }}
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "AppSlide",
    props: {
        logo: String,
        title: {
            type: String,
            required: true,
        },
        description: String,
        detailLink: {
            type: String,
            required: true,
        },
        miniappLink: {
            type: String,
            required: true,
        },
    },
};
</script>

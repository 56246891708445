<template>
    <div class="absolute inset-0 h-fit w-full bg-[#f2f2f7]">
        <!-- bg image -->
        <img
            src="/assets/imgs/views/wallet/wallet-bg.svg"
            alt=""
            class="absolute left-0 top-0 z-0 w-full object-contain"
        />
        <div
            class="relative flex size-full flex-col items-center gap-6 overflow-x-hidden px-6 py-4"
        >
            <DiscoverApp />
            <Popular />
            <Essential />
        </div>
    </div>
</template>

<script>
import Popular from "./components/popular/Popular.vue";
import Essential from "./components/essential/Essential.vue";
import DiscoverApp from "./components/app/DiscoverApp.vue";

export default {
    name: "Discover",
    components: {
        DiscoverApp,
        Popular,
        Essential,
    },
};
</script>

<template>
    <div class="absolute left-0 top-0 h-full w-full">
        <!-- check-in page content wrapper -->
        <div v-if="isBalanceInit" class="relative h-full w-full bg-[#f2f2f7]">
            <!-- bg image -->
            <img
                src="/assets/imgs/views/wallet/wallet-bg.svg"
                alt=""
                class="absolute left-0 top-0 z-0 w-full object-contain"
            />

            <!-- content -->
            <div
                class="relative flex h-full w-full flex-col items-center gap-4 px-6 py-4"
            >
                <!-- credit balance wrapper -->
                <div
                    class="flex w-full flex-col items-center justify-center gap-1 text-center"
                >
                    <div
                        class="flex h-20 w-20 items-center justify-center rounded-full bg-[#FFCD00] bg-opacity-25 shadow-[0px_0px_30px_0px_rgba(255,204,0,0.48)]"
                    >
                        <div
                            class="flex h-16 w-16 items-center justify-center rounded-full bg-[#FFCD00] bg-opacity-25"
                        >
                            <img
                                @error="
                                    (e) =>
                                        (e.target.src =
                                            '/assets/imgs/utils/token/default.png')
                                "
                                :src="`assets/imgs/utils/token/${currentCreditsTokenName}.png`"
                                alt="credits_point"
                                class="h-12 w-12"
                            />
                        </div>
                    </div>
                    <span
                        class="whitespace-nowrap text-center text-[32px] font-bold leading-10 text-[#111]"
                        >{{ creditsBalance }}</span
                    >
                    <span class="text-center text-sm font-bold text-[#111]">{{
                        currentCreditsTokenName
                    }}</span>
                </div>
                <!-- tasks wrapper container -->
                <div class="no-scrollbar w-full flex-grow overflow-scroll">
                    <!-- tasks wrapper -->
                    <div class="flex w-full flex-col gap-4">
                        <!-- Invite friend -->
                        <InviteFriends
                            @openInvitationPanel="openInvitationPanel"
                        />
                        <!-- daily tasks -->
                        <div class="flex w-full flex-col gap-4">
                            <!-- daily tasks title -->
                            <span class="text-lg font-bold text-[#111]">{{
                                $t("earnCredits.dailyTaskTitleText")
                            }}</span>
                            <DailyCheckInTask />
                            <!-- daily tasks wrapper -->
                            <div
                                v-if="tasks"
                                class="flex w-full flex-col gap-3"
                            >
                                <Task
                                    v-for="task in dailyTasks"
                                    :key="task._id"
                                    :task="task"
                                    :checkingTasks.sync="checkingTasks"
                                    :pendingTasks.sync="pendingTasks"
                                    :successTasks.sync="successTasks"
                                    @onCheckSuccess="handleOnCheckSuccess"
                                />
                            </div>
                            <!-- skeleton loader -->
                            <div v-else class="flex w-full flex-col gap-3">
                                <div
                                    v-for="i in 3"
                                    :key="i"
                                    class="h-14 w-full animate-pulse rounded-2xl bg-[#fff]"
                                ></div>
                            </div>
                        </div>
                        <!-- explore tasks -->
                        <div class="flex w-full flex-col gap-4">
                            <!-- explore tasks title -->
                            <span class="text-lg font-bold text-[#111]">{{
                                $t("earnCredits.exploreTaskTitleText")
                            }}</span>
                            <!-- explore tasks wrapper -->
                            <div
                                v-if="tasks"
                                class="flex w-full flex-col gap-3"
                            >
                                <!-- explore tasks wrapper -->
                                <div
                                    v-if="tasks"
                                    class="flex w-full flex-col gap-3"
                                >
                                    <Task
                                        v-for="task in exploreTasks"
                                        :key="task._id"
                                        :task="task"
                                        :checkingTasks.sync="checkingTasks"
                                        :pendingTasks.sync="pendingTasks"
                                        :successTasks.sync="successTasks"
                                        @onCheckSuccess="getCreditTasks"
                                    />
                                </div>
                            </div>
                            <!-- skeleton loader -->
                            <div v-else class="flex w-full flex-col gap-3">
                                <div
                                    v-for="i in 4"
                                    :key="i"
                                    class="h-14 w-full animate-pulse rounded-2xl bg-[#fff]"
                                ></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- credit records entrance -->
            <button
                @click="creditRecordsBtnOnClick"
                class="absolute right-0 top-4 z-10 flex h-8 items-center justify-center gap-1 rounded-l-full bg-[#fff] bg-opacity-65 px-2"
            >
                <span class="text-sm text-[#9699B5]">{{
                    $t("earnCredits.creditRecordsBtnText")
                }}</span>
                <svg-icon
                    type="mdi"
                    :path="mdiChevronRight"
                    :size="16"
                    class="text-base text-[#9699B5]"
                ></svg-icon>
            </button>
            <InvitationPanel ref="InvitationPanel" />
        </div>
        <Loading v-else :fullScreen="true" />
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getPrecisionValue } from "@/utils/textFormat";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiChevronRight } from "@mdi/js";
import taskAPI from "@/apis/task";
import DailyCheckInTask from "./components/DailyCheckInTask.vue";
import Task from "./components/Task.vue";
import Loading from "@/components/Loading.vue";
import InviteFriends from "./components/InviteFriends.vue";
import InvitationPanel from "../invitation/components/InvitationPanel.vue";

export default {
    name: "EarnCredits",
    components: {
        SvgIcon,
        DailyCheckInTask,
        Task,
        Loading,
        InviteFriends,
        InvitationPanel,
    },
    data: () => ({
        mdiChevronRight,
        isBalanceInit: false,
        tasks: null,
        pendingTasks: [], // 加入 taskId
        checkingTasks: [], // 確認中的 taskId
        successTasks: [], // 成功的 taskId
    }),
    computed: {
        ...mapGetters([
            "currentCreditsTokenName",
            "currentCreditsBalance",
            "userInfo",
        ]),
        creditsBalance() {
            return getPrecisionValue(
                this.currentCreditsTokenName,
                this.currentCreditsBalance,
            );
        },
        dailyTasks() {
            return (
                (this.tasks &&
                    this.tasks.length &&
                    this.tasks.filter((task) => task.refreshType === "day")) ||
                null
            );
        },
        exploreTasks() {
            return (
                (this.tasks &&
                    this.tasks.length &&
                    this.tasks.filter((task) => task.refreshType === "no")) ||
                null
            );
        },
    },
    methods: {
        async updateBalance() {
            try {
                const err = await this.$store.dispatch("updateUserInfo");
                if (err) {
                    throw new Error(JSON.stringify(err));
                } else {
                    this.isBalanceInit = true;
                }
            } catch (err) {
                window.Telegram.WebApp.showAlert(err);
            }
        },
        async getCreditTasks() {
            try {
                const res = await taskAPI.getCreditsTaskList();
                if (res.status !== 200) {
                    throw new Error(res);
                } else {
                    console.log("getCreditTasks res: ", res);
                    const tasks = res.data;
                    tasks.sort((a, b) => {
                        if (a.status !== 0 && b.status === 0) {
                            return 1;
                        }
                        if (a.status === 0 && b.status !== 0) {
                            return -1;
                        }
                        return 0;
                    });
                    this.tasks = tasks;
                }
            } catch (err) {
                console.log("getCreditTasks err: ", err);
            }
        },
        creditRecordsBtnOnClick() {
            this.$router.push({ name: "credit-records" });
        },
        handleOnCheckSuccess() {
            // 重新獲取任務列表，更新任務狀態
            this.getCreditTasks();
            // 任務成功，三秒後刷新餘額
            setTimeout(this.updateBalance, 3000);
        },
        openInvitationPanel() {
            this.$refs.InvitationPanel.show();
        },
    },
    created() {
        this.updateBalance();
        this.getCreditTasks();
    },
};
</script>

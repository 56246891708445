<template>
    <div
        v-if="isExist"
        @click="giftCoverOnclick"
        class="background fixed left-0 top-0 z-10 flex h-screen w-screen justify-center bg-[#fff] p-6 transition-all duration-300"
        :class="{ opening: isAnimating, '!p-0': isDoingTask }"
    >
        <!-- envelope -->
        <div
            class="relative flex h-full w-full flex-col items-center justify-end overflow-hidden rounded-3xl shadow-[0_10px_20px_rgba(_100,_100,_100,_0.5)]"
            :class="{ '!rounded-none': isDoingTask }"
            :style="{ background: backgroundColor }"
        >
            <!-- envelope cover -->
            <div
                class="envelope-cover absolute left-0 top-0 h-[calc(100%-120px)] w-full rounded-t-3xl"
                :class="{
                    opening: isAnimating,
                    '!rounded-t-none': isDoingTask,
                }"
            >
                <img
                    :src="
                        'https://www.tongifts.app/static/tg_gift_img/cover_' +
                        cover +
                        '.png'
                    "
                    alt=""
                    class="relative h-full w-full rounded-t-3xl object-cover object-bottom pb-12"
                    :class="{ '!rounded-t-none': isDoingTask }"
                    :style="{
                        filter: 'drop-shadow(0px 4px 16px rgba(0,0,0,.16))',
                    }"
                />
                <!-- text -->
                <span
                    v-if="!isDoingTask && isShowWords && !isGiftDisabled"
                    class="absolute bottom-1/3 left-1/2 max-w-[350px] -translate-x-1/2 text-center text-2xl font-bold"
                    :style="{ color: desTextColor }"
                    >{{
                        $t("receiveGifts.giftCover.titleText", {
                            senderName: giftSenderName,
                        })
                    }}</span
                >
                <!-- disabled text wrapper -->
                <div
                    v-if="isGiftDisabled"
                    class="absolute bottom-1/2 left-0 flex w-full translate-y-1/3 flex-col items-center gap-4 bg-[radial-gradient(50%_50%_at_50%_50%,rgba(0,0,0,0.16)_0%,rgba(0,0,0,0)_100%)] py-10"
                >
                    <span
                        class="text text-center text-base leading-5 text-[#fff]"
                        v-html="$t('receiveGifts.giftCover.exclusiveText')"
                    ></span>
                    <img
                        src="assets/imgs/views/receiveGifts/exclusive_gift.svg"
                        alt=""
                        class="w-full"
                    />
                </div>
                <!-- tasks -->
                <Tasks
                    v-if="isDoingTask"
                    @tasksOnCompleted="isTasksCompleted = true"
                    @openInvitationPanel="$emit('inviteBtnOnClick')"
                    :gift-info="giftInfo"
                    :should-refresh-tasks="shouldRefreshTasks"
                />
                <!-- sticker -->
                <div
                    @click="stickerOnClick"
                    class="sticker absolute bottom-2 left-[calc(50%-50px)] z-0 flex h-[100px] w-[100px] origin-center -translate-x-1/2 translate-y-0 transform-gpu items-center justify-center rounded-full bg-[#ffd1a0] shadow-[0px_4px_4px_rgba(0,0,0,0.25)]"
                    :class="{
                        flipping: isLoading,
                        'animate-pulse': isCheckingTask,
                        pinging:
                            (!isLocked &&
                                !isCheckingTask &&
                                !isAnimating &&
                                !isGiftDisabled) ||
                            (isDoingTask &&
                                isTasksCompleted &&
                                !isAnimating &&
                                !isGiftDisabled),
                    }"
                    :style="{
                        background: stickerBgColor,
                    }"
                >
                    <div
                        class="flex h-full w-full items-center justify-center rounded-full border-4"
                        :style="{ borderColor: openBtnColor }"
                    >
                        <svg-icon
                            v-if="isGiftDisabled"
                            type="mdi"
                            :path="mdiLockOutline"
                            :size="48"
                            class="h-12 w-12 text-[#fff]"
                        ></svg-icon>
                        <span
                            v-else
                            class="text-center text-xl font-bold"
                            :style="{ color: openBtnColor }"
                        >
                            {{ $t("receiveGifts.giftCover.stickerText") }}
                        </span>
                    </div>
                </div>
            </div>
            <InviteBtn
                v-if="isDoingTask && isInviteType"
                @inviteBtnOnClick="$emit('inviteBtnOnClick')"
                :gift-info="giftInfo"
            />
            <button
                v-if="!isDoingTask"
                @click.stop="viewReceivedDetailBtnOnClick"
                class="absolute bottom-10 left-1/2 flex h-6 -translate-x-1/2 items-center justify-center rounded-full bg-[#000] bg-opacity-30 px-3 text-center text-xs text-[#fff]"
            >
                {{ $t("receiveGifts.giftCover.viewReceivedDetailBtnText") }}
            </button>
        </div>
    </div>
</template>

<script>
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiChevronRight, mdiLockOutline } from "@mdi/js";
import { debounce } from "lodash";
import InviteBtn from "./components/InviteBtn.vue";
import Tasks from "./components/tasks/Tasks.vue";

export default {
    name: "GiftCover",
    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
        isOpening: {
            type: Boolean,
            default: false,
        },
        giftInfo: {
            type: Object,
            default: null,
        },
        giftSenderName: {
            type: String,
            default: "Someone",
        },
        isReceived: {
            type: Boolean,
            default: false,
        },
        isCheckingTask: {
            type: Boolean,
            default: false,
        },
        coverId: {
            type: String,
            default: "newdefault",
        },
        isGiftDisabled: {
            type: Boolean,
            default: false,
        },
        isLocked: {
            type: Boolean,
            default: false,
        },
        gift: {
            type: Object,
        },
    },
    components: {
        SvgIcon,
        Tasks,
        InviteBtn,
    },
    data: () => ({
        mdiChevronRight,
        mdiLockOutline,
        isAnimating: false,
        isExist: true,
        isDoingTask: false,
        isTasksCompleted: false,
        // 用來讓 Tasks 子元件 監聽是否該更新任務的 hook
        shouldRefreshTasks: false,
    }),
    computed: {
        //红包底色
        backgroundColor() {
            // 返回对应的颜色，如果没有找到则返回默认颜色
            return (
                (this.gift &&
                    this.gift.theme &&
                    this.gift.theme.coverBgColor) ||
                "#3A1691"
            );
        },

        //Gifts to you 文字颜色
        desTextColor() {
            // 返回对应的颜色，如果没有找到则返回默认颜色
            return (
                (this.gift && this.gift.theme && this.gift.theme.wordsColor) ||
                "#fff"
            );
        },

        //open 按钮背景填充色
        stickerBgColor() {
            // 返回对应的颜色，如果没有找到则返回默认颜色
            return (
                (this.gift &&
                    this.gift.theme &&
                    this.gift.theme.openBtnBgColor) ||
                "#FFC90C"
            );
        },

        //open 按钮文字颜色
        openBtnColor() {
            // 返回对应的颜色，如果没有找到则返回默认颜色
            return (
                (this.gift &&
                    this.gift.theme &&
                    this.gift.theme.openBtnColor) ||
                "#FFC90C"
            );
        },

        //红包封面
        cover() {
            // 返回对应的颜色，如果没有找到则返回默认颜色
            return this.coverId || "newdefault";
        },

        //是否显示祝福语
        isShowWords() {
            // 返回对应的颜色，如果没有找到则返回默认颜色
            if (
                this.gift &&
                this.gift.theme &&
                this.gift.theme.isShowWords === false
            ) {
                return false;
            } else {
                return true;
            }
        },

        // 是否為邀請型禮物
        isInviteType() {
            return (
                this.giftInfo &&
                this.giftInfo.invitesRequired &&
                this.giftInfo.invitesRequired > 0
            );
        },
    },
    watch: {
        // 從未領收到領收 = 需要開紅包動畫開始進行
        isReceived(newVal, oldVal) {
            console.log("isReceived: ", newVal);
            if (newVal && !oldVal) {
                this.isAnimating = true;
            }
        },
        // 1.2 秒之後通知結束動畫 並且 隱藏此頁面
        isAnimating(newVal, oldVal) {
            setTimeout(() => {
                this.isExist = false;
                this.isAnimating = false;
                this.$emit("endIsOpening");
            }, 1200);
        },
        // // 另外獲取 giftInfo 紅包鎖定狀態，讓用戶在完成全部任務時能夠局部的在此元件內部更新可否開啟紅包的狀態
        // isLocked(newVal) {
        //     this.isTasksCompleted = !newVal;
        // },
    },
    methods: {
        stickerOnClick() {
            if (this.isLoading || this.isAnimating || this.isOpening) return;
            if (this.isDoingTask) {
                if (this.isTasksCompleted) {
                    this.$emit("giftCoverOnOpen");
                } else {
                    console.log("update task status");
                    this.shouldRefreshTasks = !this.shouldRefreshTasks;
                }
            }
        },
        viewReceivedDetailBtnOnClick() {
            //TODO 跳轉到查看頁面
            const giftId = this.giftInfo && this.giftInfo._id;
            this.$router.push({ query: { id: giftId, check: "1" } });
        },
        giftCoverOnclick: debounce(function () {
            if (
                this.isLoading ||
                this.isOpening ||
                this.isAnimating ||
                this.isCheckingTask ||
                this.isDoingTask ||
                this.isGiftDisabled
            )
                return;
            if (!this.isLocked) {
                this.$emit("giftCoverOnOpen");
            } else {
                console.log("doTask");
                this.isDoingTask = true;
                // this.$router.push({
                //     name: "dotasks",
                //     params: { giftInfo: this.giftInfo },
                // });
            }
        }, 300),
    },
};
</script>

<style lang="scss" scoped>
.background {
    &.opening {
        animation: background-fade 0.5s ease-in;
        animation-delay: 0.8s;
    }
}

.envelope-cover {
    &.opening {
        animation: cover-slide-up 1.2s ease-in;
    }
}

.sticker {
    // 避免 ios 上元件消失狀況
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    -webkit-perspective: 1000;

    &.flipping {
        animation: sticker-flipping 1.2s infinite;
    }
    &.pinging {
        animation: sticker-pinging 1.2s infinite;
    }
}

.lock-icon {
    width: 150px;
    height: 150px;
}

@-webkit-keyframes sticker-flipping {
    from {
        -webkit-transform: rotateY(360deg);
    }
    to {
        -webkit-transform: rotateY(0deg);
    }
}
@keyframes sticker-flipping {
    from {
        -webkit-transform: rotateY(360deg);
    }
    to {
        -webkit-transform: rotateY(0deg);
    }
}

@-webkit-keyframes sticker-pinging {
    10% {
        -webkit-transform: scale(1.1);
    }
    to {
        -webkit-transform: scale(1);
    }
}
@keyframes sticker-pinging {
    10% {
        -webkit-transform: scale(1.1);
    }
    to {
        -webkit-transform: scale(1);
    }
}

@-webkit-keyframes cover-slide-up {
    from {
        -webkit-transform: translate(0%, 0%);
    }
    to {
        -webkit-transform: translate(0%, -120%);
    }
}
@keyframes cover-slide-up {
    from {
        transform: translate(0%, 0%);
    }
    to {
        transform: translate(0%, -120%);
    }
}

@-webkit-keyframes background-fade {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        display: none;
    }
}
@keyframes background-fade {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        display: none;
        scale: 1.05;
    }
}
</style>

import axios from "axios";

const baseURL = process.env.NODE_ENV === "production" ? "/api" : "/api";

const axiosInstance = axios.create({
    baseURL,
    timeout: 30000,
});

axiosInstance.interceptors.request.use(
    (config) => {
        config.timeout = 30000;
        if (
            process.env.NODE_ENV === "production" ||
            process.env.NODE_ENV === "development"
        ) {
            // 如果 token 存在的話，則帶入到 headers 當中
            if (
                window.Telegram &&
                window.Telegram.WebApp &&
                window.Telegram.WebApp.initData
            ) {
                config.headers["initdata"] = window.Telegram.WebApp.initData;
                config.headers.platform = window.Telegram.WebApp.platform;
                config.headers.version = window.Telegram.WebApp.version;
            }
        } else {
            config.headers["initData"] =
                // "query_id=AAGBrdAzAgAAAIGt0DOnHDSo&user=%7B%22id%22%3A5164281217%2C%22first_name%22%3A%22Jerry%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22Jerrylucks%22%2C%22language_code%22%3A%22en%22%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1711784747&hash=e69be2c4d1e1a4fce31987cdae31b98452730c85b66c6fc3150c8f9eaf593522"
                // "query_id=AAHSLZNqAAAAANItk2oABzhP&user=%7B%22id%22%3A1788030418%2C%22first_name%22%3A%22Ether%20%7C%20%E8%8B%B1%E9%9B%84%E5%8F%AF%E4%BB%A5%E5%8F%97%E5%A7%94%E5%B1%88%20%E4%BD%86%E6%98%AF%E4%BD%A0%E4%B8%8D%E8%83%BD%E8%B8%A9%E6%88%91%E7%9A%84%E5%88%87%E7%88%BE%E8%A5%BF%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22fluorodeoxyglucose%22%2C%22language_code%22%3A%22en%22%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1710128025&hash=30223877f086e306b516e47732391946398e5e36e65949b1e475cb597cbb8516";
                "query_id=AAHSLZNqAAAAANItk2oyUPX-&user=%7B%22id%22%3A1788030418%2C%22first_name%22%3A%22Ether%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22fluorodeoxyglucose%22%2C%22language_code%22%3A%22zh-hant%22%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1716793887&hash=013b62bff53cd2ce120e9521a78d28f28094a2667182f1488353c6536ebf1154"; // Ether
                // "query_id=AAF4nrMyAAAAAHieszK6hvGb&user=%7B%22id%22%3A850632312%2C%22first_name%22%3A%22zac%22%2C%22last_name%22%3A%22oooooo%22%2C%22username%22%3A%22amarLayiis%22%2C%22language_code%22%3A%22en%22%2C%22is_premium%22%3Atrue%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1711815353&hash=0186842ebdd32da93be4f98dd689958e4dcc9b41ce34d2318ac6019093fc38cd"; // amar
                // "query_id=AAEh6K99AAAAACHor31YG0K5&user=%7B%22id%22%3A2108680225%2C%22first_name%22%3A%22Meet%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22meetupuuu%22%2C%22language_code%22%3A%22en%22%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1714557279&hash=6bcee5640517562d9e3dca20483b273b487c0b94faf7fe7814fb6d1c148bdb48"; // meet
                // "query_id=AAG0DFV7AAAAALQMVXsmr6Yp&user=%7B%22id%22%3A2069171380%2C%22first_name%22%3A%22David%22%2C%22last_name%22%3A%22N%22%2C%22username%22%3A%22khankluan2420%22%2C%22language_code%22%3A%22en%22%2C%22is_premium%22%3Atrue%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1728281470&hash=45f73eba080a0147007f757d32480e05e9136fd910fa2bd2cf5b291ac1cb76f3"; // David
        }
        return config;
    },
    (err) => Promise.reject(err),
);

export const apiHelper = axiosInstance;

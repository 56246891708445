export default {
    popupClosed: {
        deposit: () => {
            console.log("go deposit page");
            const botLink = process.env.VUE_APP_BOT_URL;
            window.Telegram.WebApp.close();
            window.open(`${botLink}?start=deposit`);
        },
    },
};

<template>
    <div class="flex w-full flex-col items-center gap-10 pt-8">
        <!-- title wrapper -->
        <div class="flex w-full flex-col items-center gap-4">
            <!-- status icon -->
            <div
                class="flex h-[68px] w-[68px] items-center justify-center rounded-full bg-[#f0f0f0]"
            >
                <svg-icon
                    type="mdi"
                    :path="mdiClose"
                    :size="56"
                    class="text-center text-[56px] text-[#aaa]"
                ></svg-icon>
            </div>
            <!-- status text -->
            <span class="text-center text-2xl font-bold text-[#000]">{{
                $t("utils.failed")
            }}</span>
        </div>
        <!-- info wrapper -->
        <div class="flex w-full flex-col items-center gap-2">
            <span class="text-center text-sm font-bold text-[#000]">{{
                $t("thirdPartyOrder.transferOutText", {
                    thirdPartyName,
                })
            }}</span>
            <span class="text-center text-sm text-[#6f6f6f]">{{
                $t("thirdPartyOrder.orderIdText", {
                    orderId,
                })
            }}</span>
        </div>
        <!-- request again button -->
        <button
            @click="confirmOrderOnClick"
            :disabled="isConfirming"
            class="flex h-10 items-center justify-center rounded-lg bg-[#FF5D8F] px-6 text-center text-base font-bold text-[#fff]"
            :class="{ 'opacity-30': isConfirming }"
        >
            {{ $t("thirdPartyOrder.requestAgainBtnText") }}
        </button>
    </div>
</template>

<script>
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiClose } from "@mdi/js";

export default {
    name: "OrderFailed",
    components: {
        SvgIcon,
    },
    props: {
        thirdPartyName: {
            type: String,
            default: "",
        },
        orderId: {
            type: String,
            default: "",
        },
        isConfirming: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        mdiClose,
    }),
    methods: {
        confirmOrderOnClick() {
            this.$emit("confirmOrderOnClick");
        },
    },
};
</script>

import { apiHelper } from "@/utils/requestHelper.js";

export default {
    createEnvelope({
        amount,
        number,
        type,
        token,
        coverId,
        tasksIds,
        inviteRewardCount,
        invitesRequired,
    }) {
        return apiHelper.post("/envelope/create", {
            amount,
            number,
            type,
            token,
            coverId,
            tasksIds,
            inviteRewardCount,
            invitesRequired,
        });
    },
    checkTasksEnvelope(id) {
        return apiHelper.get(`/envelope/checktasks?id=${id}`);
    },
    getEnvelope(id, isFixGiftId, inviterId) {
        if (isFixGiftId)
            return apiHelper.get(
                `/envelope/info?id=${id}&f=1${inviterId ? "&iid=" + inviterId : ""}`,
            );
        else
            return apiHelper.get(
                `/envelope/info?id=${id}${inviterId ? "&iid=" + inviterId : ""}`,
            );
    },
    openEnvelope(id, userinvite) {
        return apiHelper.post(`/envelope/open`, { id, userinvite });
    },
    endEnvelope(id) {
        return apiHelper.post(`/envelope/end`, { id });
    },
    getSentGifts({ date, skip = 0, limit = 10 }) {
        return apiHelper.get(
            `/envelope/list?date=${date}&skip=${skip}&limit=${limit}`,
        );
    },
    getReceivedGifts({ date, skip = 0, limit = 10 }) {
        return apiHelper.get(
            `/envelope/receivedlist?date=${date}&skip=${skip}&limit=${limit}`,
        );
    },
    getUserAddress(token) {
        return apiHelper.get(`/user/getaddress?token=${token}`);
    },
    getGifts() {
        return apiHelper.get(`/envelope/gifts`);
    },
    getBanner() {
        return apiHelper.get(`/envelope/banner`);
    },
    getInvitationGift() {
        return apiHelper.get(`/envelope/invitationGift`);
    },
};

import confetti from "canvas-confetti";

export function shootConfetti(durationSec = 1) {
    const duration = durationSec * 1000; // 持续时间 2 秒
    const end = Date.now() + duration;

    (function frame() {
        // 每帧喷出彩花
        confetti({
            particleCount: 3,
            angle: 60,
            spread: 55,
            origin: { x: 0 },
        });
        confetti({
            particleCount: 3,
            angle: 120,
            spread: 55,
            origin: { x: 1 },
        });

        // 如果当前时间小于结束时间，继续喷彩花
        if (Date.now() < end) {
            requestAnimationFrame(frame);
        }
    })();
}

<template>
    <div
        @click="taskBtnOnClick"
        class="flex min-h-14 items-center gap-3 rounded-2xl bg-[#fff] px-4 py-3"
    >
        <!-- task image -->
        <img
            @error="(e) => (e.target.src = `/assets/imgs/utils/token/GFT.png`)"
            :src="taskImgPath"
            alt=""
            class="h-10 w-10 rounded-lg object-contain"
        />
        <!-- task content -->
        <div class="flex flex-grow flex-col gap-1">
            <!-- task title text -->
            <span class="text-sm font-bold leading-4 text-[#111]">{{
                taskName
            }}</span>
            <!-- task reward amount -->
            <div class="flex items-center gap-1">
                <img
                    @error="
                        (e) =>
                            (e.target.src =
                                '/assets/imgs/utils/token/default.png')
                    "
                    :src="`assets/imgs/utils/token/${currentCreditsTokenName}.png`"
                    alt="credits_point"
                    class="h-4 w-4"
                />
                <span class="text-xs text-[#FFC90C]">{{
                    `+${task.amount}`
                }}</span>
            </div>
        </div>
        <!-- completed icon -->
        <div
            v-show="task.status === 2 || successTasks.includes(task._id)"
            class="flex h-6 w-6 items-center justify-center rounded-full bg-[#E3FFE9]"
        >
            <svg-icon
                type="mdi"
                :path="mdiCheck"
                :size="20"
                class="text-[#00DD31]"
            ></svg-icon>
        </div>
        <!-- action button -->
        <button
            v-show="
                task.status === 0 &&
                !pendingTasks.includes(task._id) &&
                !successTasks.includes(task._id)
            "
            class="tex-center flex h-8 min-w-20 items-center justify-center rounded-lg bg-[#FFC90C] px-4 text-sm font-bold text-[#fff]"
        >
            {{ taskBtnText }}
        </button>
        <!-- refresh button -->
        <button
            v-show="
                task.status === 0 &&
                pendingTasks.includes(task._id) &&
                !successTasks.includes(task._id)
            "
            @click.stop="refreshBtnOnClick(task)"
            :disabled="checkingTasks.includes(task._id)"
            class="h-6 w-6"
        >
            <svg-icon
                type="mdi"
                :path="mdiRefresh"
                :size="20"
                class="text-xl text-[#9699B5]"
                :class="{
                    '!animate-spin': checkingTasks.includes(task._id),
                }"
            ></svg-icon>
        </button>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import taskAPI from "@/apis/task";
import SvgIcon from "@jamescoyle/vue-icon";
import { shootConfetti } from "@/utils/confetti";
import { mdiCheck, mdiRefresh } from "@mdi/js";

export default {
    name: "Task",
    components: {
        SvgIcon,
    },
    props: {
        task: {
            type: Object,
            default: null,
        },
        checkingTasks: {
            type: Array,
            default: [],
        },
        pendingTasks: {
            type: Array,
            default: [],
        },
        successTasks: {
            type: Array,
            default: [],
        },
    },
    data: () => ({
        mdiCheck,
        mdiRefresh,
        waitingTimer: null,
    }),
    computed: {
        ...mapGetters(["currentCreditsTokenName"]),
        taskName() {
            const currentLocale = this.$i18n.locale;
            const taskInfo = this.task.task;
            return (
                (taskInfo &&
                    taskInfo.language &&
                    taskInfo.language[`title-${currentLocale}`]) ||
                taskInfo.title
            );
        },
        taskImgPath() {
            const taskIconPath = this.task && this.task.icon;
            if (taskIconPath) {
                const isLocalhost = window.location.hostname === "localhost";
                return isLocalhost
                    ? `https://devmini.tongifts.app${taskIconPath}`
                    : taskIconPath;
            }
            const taskInfo = this.task.task;
            const type = taskInfo && taskInfo.type;
            if (type === "joinGroupOrChannel") {
                return "assets/imgs/views/earnCredits/telegram.png";
            }
            if (type === "followTwitterAccount") {
                return "assets/imgs/views/earnCredits/twitter.png";
            }

            return `assets/imgs/utils/token/${this.currentCreditsTokenName}.png`;
        },
        taskBtnText() {
            const taskInfo = this.task.task;
            const type = taskInfo && taskInfo.type;
            if (!type) return "-";
            if (type === "joinGroupOrChannel")
                return this.$t("earnCredits.taskBtnText.join");
            if (type === "openLink") {
                return taskInfo.link === "SHARE"
                    ? this.$t("earnCredits.taskBtnText.share")
                    : this.$t("earnCredits.taskBtnText.go");
            }
            if (type === "followTwitterAccount")
                return this.$t("earnCredits.taskBtnText.follow");
            return this.$t("earnCredits.taskBtnText.go");
        },
    },
    methods: {
        async completeTask(taskId) {
            try {
                const res = await taskAPI.updateTask({
                    taskId,
                    status: "completed",
                });
                if (res.status !== 200) {
                    throw new Error(res);
                } else {
                    console.log("completeTask res: ", res);
                }
            } catch (err) {
                console.log("completeTask err: ", err);
            }
        },
        async checkTaskCompletion(task) {
            console.log("task: ", task);
            const taskId = task._id;
            try {
                const res = await taskAPI.checkTask({
                    taskId,
                });
                if (res.status !== 200) {
                    throw new Error(res);
                } else {
                    console.log("checkTaskCompletion res: ", res);
                    // 有成功確認任務，就刷新任務列表
                    const result = res.data;
                    if (result === "success") {
                        shootConfetti(0.2);
                        const rewardAmount = task.amount || 0;
                        const rewardTokenName = task.token || "";
                        this.$message.success(
                            this.$t("messages.success.earnTokenMessage", {
                                amount: rewardAmount,
                                tokenName: rewardTokenName,
                            }),
                            3,
                        );
                        const successTasks = [...this.successTasks, taskId];
                        this.$emit("update:successTasks", successTasks);
                        this.$emit("onCheckSuccess");
                    }
                }
            } catch (err) {
                console.log("checkTaskCompletion err: ", err);
                this.$message.error(
                    this.$t("earnCredits.messages.taskIncompleteMessageText"),
                    3,
                );
            }
            const filteredPendingTasks = this.pendingTasks.filter(
                (_task) => _task !== taskId,
            );
            const filteredCheckingTasks = this.checkingTasks.filter(
                (_task) => _task !== taskId,
            );
            this.$emit("update:pendingTasks", filteredPendingTasks);
            this.$emit("update:checkingTasks", filteredCheckingTasks);
        },
        taskBtnOnClick() {
            const task = this.task;
            // 將任務加入 pending 列表
            if (!this.pendingTasks.includes(task._id)) {
                this.pendingTasks.push(task._id);
            }
            const taskInfo = task.task;
            // ######## 沃草，開始囉 嘻嘻！########

            // 等待類型任務 (啥都不用做 等就對了)
            if (
                taskInfo.type === "thirdPartyTask" &&
                (!taskInfo.link || !taskInfo.link.length)
            ) {
                console.log("JUST WAIT...");
                return;
            }

            // 加入聊天室
            if (taskInfo.checkChatId) {
                if (taskInfo.link && taskInfo.type === "joinGroupAndChannel") {
                    window.Telegram.WebApp.openTelegramLink(taskInfo.link);
                } else {
                    // 移除 checkChatId 中的 "@" 符号
                    const chatId = taskInfo.checkChatId.replace("@", "");
                    // 使用清理后的 chatId 构建 Telegram 群组链接
                    const telegramGroupUrl = `https://t.me/${chatId}`;
                    // 打开 Telegram 群组链接
                    window.Telegram.WebApp.openTelegramLink(telegramGroupUrl);
                }
                return;
            }

            // 追蹤 X
            if (taskInfo.link && taskInfo.type === "followTwitterAccount") {
                this.handleTwitterLogin(taskInfo);
                return;
            }

            // OPEN LINK
            if (taskInfo.link && taskInfo.type !== "followTwitterAccount") {
                // 直接觸發就能完成的任務
                if (taskInfo.type === "openLink") {
                    this.completeTask(taskInfo._id);
                }
                // 如果任務 link 是 SHARE 就拉起分享介面
                if (taskInfo.link === "SHARE") {
                    this.$emit("openInvitationPanel");
                } else {
                    const taskLink = taskInfo.link;
                    const currentUserId = this.userId;
                    const replacedLink = taskLink.replace(
                        "{tid}",
                        currentUserId,
                    );
                    window.open(replacedLink);
                }
                return;
            }

            // Send gift
            if (taskInfo.type === "sendGift") {
                this.$router.push("/sendGifts");
            }
        },
        refreshBtnOnClick(task) {
            const taskId = task._id;
            const checkingTasks = [...this.checkingTasks, taskId];
            this.$emit("update:checkingTasks", checkingTasks);
            // 五秒鐘後驗證是否成功
            this.waitingTimer = setTimeout(() => {
                this.checkTaskCompletion(task);
            }, 5000);
        },
    },
    beforeDestroy() {
        clearTimeout(this.waitingTimer);
        this.waitingTimer = null;
    },
};
</script>

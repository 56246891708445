<template>
    <VueSlickCarousel
        class="w-screen"
        :class="className"
        v-bind="settings"
        :arrows="false"
    >
        <slot></slot>
    </VueSlickCarousel>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
    name: "Carousel",
    components: {
        VueSlickCarousel,
    },
    props: {
        className: String,
        carouselSettings: Object,
    },
    watch: {
        carouselSettings: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    this.settings = { ...this.settings, ...newVal };
                }
            },
        },
    },
    data: () => ({
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: "0",
            infinite: false,
        },
    }),
};
</script>

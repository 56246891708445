var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"absolute left-0 top-0 h-full w-full"},[(!_vm.isLoading && _vm.currentTokenName)?_c('div',{staticClass:"flex h-full w-full flex-col bg-[#F2F2F7]"},[_c('div',{staticClass:"flex h-14 min-h-14 w-full items-center justify-between px-6"},[_c('div',{staticClass:"flex items-center gap-2"},[_c('img',{staticClass:"h-8 w-8 rounded-full border border-[#fff]",attrs:{"src":_vm.currentUserProfilePicUrl ||
                        '/assets/imgs/utils/avatar/avatar_default.svg',"alt":"user_profile_picture"},on:{"error":(e) =>
                            (e.target.src =
                                '/assets/imgs/utils/avatar/avatar_default.svg')}}),_c('span',{staticClass:"max-w-[200px] truncate text-base font-bold text-[#111]"},[_vm._v(_vm._s(_vm.$t("assets.titleText")))])]),_c('button',{staticClass:"flex h-8 items-center justify-center gap-1 rounded-lg bg-[#0088CC] px-4 text-center",on:{"click":_vm.connectWalletBtnOnClick}},[_c('img',{staticClass:"h-3 w-3",attrs:{"src":"assets/imgs/views/dailyCheckIn/logo_ton.svg","alt":""}}),_c('span',{staticClass:"text-xs font-bold text-[#fff]"},[_vm._v(_vm._s(_vm.currentConnectedWalletAddress ? _vm.slicedConnectedWalletAddress : _vm.$t("assets.connectWalletBtnText")))])])]),_c('div',{staticClass:"flex h-[calc(100%-56px)] w-full flex-shrink flex-grow flex-col gap-2 rounded-t-2xl bg-[#fff] px-6 py-4"},[_c('div',{staticClass:"flex w-full flex-col gap-3"},[_c('div',{staticClass:"flex w-full flex-col"},[_c('div',{staticClass:"flex w-full items-center justify-between"},[_c('div',{staticClass:"flex items-end gap-2"},[_c('img',{staticClass:"size-10",attrs:{"src":`assets/imgs/utils/token/${_vm.currentTokenName}.png`,"alt":"token-image"},on:{"error":(e) =>
                                        (e.target.src =
                                            '/assets/imgs/utils/token/default.png')}}),_c('span',{staticClass:"text-[32px] font-bold text-[#111] leading-10"},[_vm._v(_vm._s(_vm.currentBalanceText))])]),_c('img',{staticClass:"h-6 w-6",attrs:{"src":"/assets/imgs/icons/assets_list.svg","alt":""},on:{"click":() =>
                                    this.$router.push({
                                        name: 'wallet-record',
                                    })}})]),_c('button',{staticClass:"flex items-center gap-1 bg-transparent",attrs:{"disabled":_vm.isFetching},on:{"click":() => _vm.updateBalance(false)}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.balanceUpdatedTime !== null),expression:"balanceUpdatedTime !== null"}],staticClass:"text-xs text-[#aaa]"},[_vm._v(_vm._s(_vm.balanceUpdatedTime))]),_c('svg-icon',{staticClass:"text-[#9699B5]",class:{ 'animate-spin': _vm.isFetching },attrs:{"type":"mdi","path":_vm.mdiRefresh,"size":"20"}})],1)]),_c('div',{staticClass:"flex w-full items-center gap-3"},[_c('button',{staticClass:"flex h-10 w-1/2 items-center justify-center rounded-lg bg-[#F0F0F0] px-6 text-base font-bold text-[#FFC90C]",class:{
                            'opacity-30': !_vm.filterTokenList.cantWithdraw,
                        },attrs:{"disabled":!_vm.filterTokenList.cantWithdraw},on:{"click":() =>
                                this.$router.push('/select-token/withdraw')}},[_vm._v(" "+_vm._s(_vm.$t("assets.withdrawBtnText"))+" ")]),_c('button',{staticClass:"flex h-10 w-1/2 items-center justify-center rounded-lg bg-[#FFC90C] px-6 text-base font-bold text-[#FFF]",class:{
                            'opacity-30': !_vm.filterTokenList.cantDeposit,
                        },attrs:{"disabled":!_vm.filterTokenList.cantDeposit},on:{"click":() => this.$router.push('/select-token/deposit')}},[_vm._v(" "+_vm._s(_vm.$t("assets.depositBtnText"))+" ")])])]),_c('div',{staticClass:"no-scrollbar w-full flex-grow overflow-scroll"},[_c('div',{staticClass:"flex w-full flex-col"},[_c('a-tabs',{staticClass:"token-tabs",attrs:{"default-active-key":"all"}},_vm._l((_vm.tokenTabs),function(tab){return (
                                _vm.filterTokenList[tab.key] &&
                                _vm.filterTokenList[tab.key].length
                            )?_c('a-tab-pane',{key:tab.key,attrs:{"tab":tab.name}},_vm._l((_vm.filterTokenList[tab.key]),function(token){return _c('TokenItem',{key:token.id,attrs:{"image":token.image,"name":token.name,"balance":token.balance,"onChainInfo":token.onChainInfo},on:{"selectToken":function($event){return _vm.selectToken(token.name)}},scopedSlots:_vm._u([{key:"3rdParty-transfer-btn",fn:function(){return [(_vm.transferConfig(token))?_c('button',{staticClass:"flex items-center",on:{"click":function($event){$event.stopPropagation();return _vm.thirdPartyTransferOnClick(token)}}},[_c('span',{staticClass:"text-xs text-[#FF5D8F]"},[_vm._v(" "+_vm._s(_vm.$t( "assets.thirdPartTransferBtnText", { thirdPartyName: _vm.transferConfig( token, ).name, }, ))+" ")]),_c('svg-icon',{staticClass:"text-sm text-[#FF5D8F]",attrs:{"type":"mdi","path":_vm.mdiChevronRight,"size":"14"}})],1):_vm._e()]},proxy:true}],null,true)})}),1):_vm._e()}),1)],1)])])]):_c('Loading',{attrs:{"full-screen":true}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
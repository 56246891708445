import { apiHelper } from "@/utils/requestHelper.js";

export default {
    getUserAddress(token) {
        return apiHelper.get(`/user/getaddress?token=${token}`);
    },

    getTokenList() {
        return apiHelper.get(`/user/token`);
    },
    getWalletRecord({ date, skip = 0, limit = 20, token = "", type = "" }) {
        return apiHelper.get(
            `/user/tokenchange?date=${date}&skip=${skip}&limit=${limit}&type=${type}&token=${token}`,
        );
    },
    deposit(payload) {
        return apiHelper.post("/user/deposit", payload);
    },
    withdraw(payload) {
        return apiHelper.post("/user/withdraw", payload);
    },
    // getAddress(token) {
    //     return apiHelper.get(`/user/address?token=${token}`);
    // },
};

<template>
    <a-dropdown
        v-model="visible"
        :disabled="disabled"
        :trigger="['click']"
        :placement="placement"
    >
        <slot name="toggle-button"></slot>
        <template #overlay>
            <a-menu class="rounded-lg py-[6px]">
                <slot name="menu-items"></slot>
                <a-menu-item
                    @click="optionOnclick(item)"
                    v-for="(item, index) in items"
                    :key="index"
                    class="flex items-center gap-2 bg-[#FFF] px-3 py-[6px]"
                >
                    <img
                        v-if="item.imgPath"
                        :src="item.imgPath"
                        alt=""
                        class="h-6 w-6"
                    />
                    <span v-if="item.text" class="text-sm text-[#000]">{{
                        item && item.text
                    }}</span>
                </a-menu-item>
            </a-menu>
        </template>
    </a-dropdown>
</template>

<script>
export default {
    name: "Dropdown",
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        align: {
            type: String,
            default: "left",
        },
        showMenu: {
            type: Boolean,
            default: false,
        },
        items: {
            type: Array,
            default: [],
        },
    },
    data: () => ({
        visible: false,
    }),
    computed: {
        placement() {
            if (this.align === "left") return "bottomLeft";
            if (this.align === "right") return "bottomRight";
        },
    },
    watch: {
        visible(newVal) {
            this.$emit("update:showMenu", newVal);
        },
    },
    methods: {
        optionOnclick(option) {
            this.$emit("optionOnclick", option);
            this.visible = false;
        },
    },
};
</script>

<template>
    <button
        class="flex items-center gap-1"
        :class="{ 'gap-1': size === 'md', 'gap-0': size === 'sm' }"
    >
        <img
            @error="
                (e) => (e.target.src = '/assets/imgs/utils/token/default.png')
            "
            :class="{
                'h-[27px] w-[27px]': size === 'md',
                'h-[16px] w-[16px]': size === 'sm',
            }"
            :src="`assets/imgs/utils/token/${currentTokenName}.png`"
            alt="token-image"
        />
        <span
            class="font-bold text-[#111]"
            :class="{ 'text-[20px]': size === 'md', 'text-sm': size === 'sm' }"
            >{{ this.currentTokenName }}</span
        >
        <svg-icon
            type="mdi"
            :path="mdiChevronRight"
            :size="iconSize"
            class="text-[#000]"
        ></svg-icon>
    </button>
</template>

<script>
import { mapGetters } from "vuex";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiChevronRight } from "@mdi/js";

export default {
    name: "TokenSelect",
    props: {
        size: {
            type: String,
            default: "md",
        },
    },
    components: {
        SvgIcon,
    },
    data: () => ({
        mdiChevronRight,
    }),
    computed: {
        ...mapGetters(["currentTokenName"]),
        iconSize() {
            if (this.size === "md") return 20;
            if (this.size === "sm") return 14;
        },
    },
    methods: {
        navigateToDeposit() {
            this.$router.push("/tokenList");
        },
    },
};
</script>

<template>
    <div class="flex w-full flex-col gap-4">
        <span class="text-lg font-bold text-[#111111]">{{
            $t("utils.popular")
        }}</span>
        <Carousel class="popular-carousel" :carousel-settings="settings">
            <div
                class="!flex max-w-fit flex-col items-center gap-1.5"
                v-for="(item, index) in renderItems()"
                :key="index"
            >
                <img
                    @click="$router.push(item.detailLink)"
                    @error="
                        (e) =>
                            (e.target.src =
                                '/assets/imgs/utils/token/default.png')
                    "
                    class="size-[68px] rounded-lg hover:cursor-pointer"
                    :src="item.imgSrc"
                />
                <span class="text-xs font-bold text-[#111111]">{{
                    item.title
                }}</span>
                <button
                    @click="$router.push(item.miniappLink)"
                    class="h-8 basis-1/5 rounded-lg bg-[#ff5c8e] px-4 text-sm font-bold leading-[1.875rem] text-white"
                >
                    {{ $t("utils.open") }}
                </button>
            </div>
        </Carousel>
    </div>
</template>

<script>
import Carousel from "@/components/Carousel.vue";

export default {
    name: "Popular",
    components: {
        Carousel,
    },
    data: () => ({
        settings: {
            slidesToShow: 4.75,
            centerMode: false,
            slidesToScroll: 4,
        },
    }),
    methods: {
        renderItems() {
            return [
                {
                    imgSrc: "/assets/imgs/utils/availableCovers/bitmart.png",
                    title: "TonGifts",
                    detailLink: "/detail",
                    miniappLink: "#",
                },
                {
                    imgSrc: "/assets/imgs/utils/availableCovers/bitmart.png",
                    title: "TonGifts",
                    detailLink: "/detail",
                    miniappLink: "#",
                },
                {
                    imgSrc: "/assets/imgs/utils/availableCovers/bitmart.png",
                    title: "TonGifts",
                    detailLink: "/detail",
                    miniappLink: "#",
                },
                {
                    imgSrc: "/assets/imgs/utils/availableCovers/bitmart.png",
                    title: "TonGifts",
                    detailLink: "/detail",
                    miniappLink: "#",
                },
                {
                    imgSrc: "/assets/imgs/utils/availableCovers/bitmart.png",
                    title: "TonGifts",
                    detailLink: "/detail",
                    miniappLink: "#",
                },
                {
                    imgSrc: "/assets/imgs/utils/availableCovers/bitmart.png",
                    title: "TonGifts",
                    detailLink: "/detail",
                    miniappLink: "#",
                },
                {
                    imgSrc: "/assets/imgs/utils/availableCovers/bitmart.png",
                    title: "TonGifts",
                    detailLink: "/detail",
                    miniappLink: "#",
                },
            ];
        },
    },
};
</script>

<template>
    <div
        class="flex items-center justify-center"
        :class="{ 'fixed left-0 top-0 h-screen w-screen': fullScreen }"
    >
        <img
            src="assets/imgs/utils/logo/ton_gifts_logo.svg"
            alt=""
            class="w-[60px] animate-ping"
        />
    </div>
</template>

<script>
export default {
    name: "Loading",
    props: {
        fullScreen: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

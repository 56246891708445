<template>
    <div class="flex w-full flex-col items-center gap-10 pt-8">
        <!-- title wrapper -->
        <div class="flex w-full flex-col items-center gap-4">
            <!-- status icon -->
            <div
                class="flex h-[68px] w-[68px] items-center justify-center rounded-full bg-[#E3FFE9]"
            >
                <svg-icon
                    type="mdi"
                    :path="mdiCheck"
                    :size="56"
                    class="text-center text-[56px] text-[#00DD31]"
                ></svg-icon>
            </div>
            <!-- status text -->
            <span class="text-center text-2xl font-bold text-[#000]">{{
                $t("utils.success")
            }}</span>
        </div>
        <!-- order info -->
        <div class="flex w-full flex-col items-center gap-4">
            <!-- info wrapper -->
            <div class="flex w-full flex-col items-center gap-2">
                <span class="text-center text-sm font-bold text-[#000]">{{
                    $t("thirdPartyOrder.transferOutText", {
                        thirdPartyName,
                    })
                }}</span>
                <span class="text-center text-sm text-[#6f6f6f]">{{
                    $t("thirdPartyOrder.orderIdText", {
                        orderId,
                    })
                }}</span>
            </div>
            <!-- order amount -->
            <div class="flex items-center gap-1">
                <img
                    @error="
                        (e) =>
                            (e.target.src =
                                '/assets/imgs/utils/token/default.png')
                    "
                    :src="`assets/imgs/utils/token/${orderTokenName}.png`"
                    alt="token-image"
                    class="h-9 w-9"
                />
                <span class="text-[40px] font-bold text-[#FFC90C]">{{
                    orderAmount
                }}</span>
                <span
                    class="flex h-10 items-end text-base font-bold text-[#FFC90C]"
                    >{{ orderTokenName }}</span
                >
            </div>
        </div>
        <!-- request again button -->
        <button
            @click="checkBtnOnClick"
            :disabled="isConfirming"
            class="flex h-10 items-center justify-center rounded-lg bg-[#FF5D8F] px-6 text-center text-base font-bold text-[#fff]"
            :class="{ 'opacity-30': isConfirming }"
        >
            {{ $t("thirdPartyOrder.backBtnText", { thirdPartyName }) }}
        </button>
    </div>
</template>

<script>
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiCheck } from "@mdi/js";

export default {
    name: "OrderSucceed",
    components: {
        SvgIcon,
    },
    props: {
        thirdPartyName: {
            type: String,
            default: "",
        },
        orderId: {
            type: String,
            default: "",
        },
        orderTokenName: {
            type: String,
            default: "",
        },
        orderAmount: {
            type: String,
            default: "0",
        },
        goThirdPartyUrl: {
            type: String,
            default: null,
        },
        isConfirming: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        mdiCheck,
    }),
    methods: {
        checkBtnOnClick() {
            if (this.goThirdPartyUrl) {
                window.open(this.goThirdPartyUrl);
            }
        },
    },
};
</script>

<template>
    <div
        class="absolute left-0 w-full translate-y-5 transform-gpu px-6 pb-6 opacity-0 transition-all duration-500"
        :class="{ '!translate-y-0 opacity-100': isShowing }"
    >
        <button
            @click="inviteBtnOnClick"
            class="flex h-14 w-full flex-nowrap items-center justify-center gap-2 rounded-lg bg-[#000] bg-opacity-30 px-6"
        >
            <img
                src="assets/imgs/icons/inviteBtn_celebrate.svg"
                alt=""
                class="h-6 w-6"
            />
            <span class="text-sm text-[#fff]">{{
                $t("receiveGifts.giftCover.inviteBtnText", {
                    inviteRewardCount: giftInfo.inviteRewardCount,
                })
            }}</span>
        </button>
    </div>
</template>

<script>
export default {
    name: "InviteBtn",
    props: {
        giftInfo: {
            type: Object,
            default: null,
        },
    },
    data: () => ({
        isShowing: false,
    }),
    methods: {
        inviteBtnOnClick() {
            this.$emit("inviteBtnOnClick");
        },
    },
    created() {
        setTimeout(() => (this.isShowing = true), 600);
    },
};
</script>

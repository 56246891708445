<template>
    <transition name="fade">
        <div
            v-if="isOptionSelectPanelActive"
            class="fixed left-0 top-0 z-10 h-screen w-screen"
        >
            <!-- overlay -->
            <div
                @click="close"
                class="absolute left-0 top-0 z-[-1] h-full w-full bg-[#000] bg-opacity-60"
            ></div>
            <!-- dialog -->
            <div
                class="absolute bottom-0 left-0 flex w-full flex-col items-center justify-center rounded-t-xl bg-[#FFF] px-6 py-4"
            >
                <!-- share with more friends  -->
                <button
                    @click="shareOptionOnClick"
                    class="flex h-14 w-full items-center justify-center gap-2"
                >
                    <svg-icon
                        type="mdi"
                        :path="mdiArrowRightTop"
                        :size="24"
                        class="text-2xl text-[#FF5D8F]"
                    ></svg-icon>
                    <span class="text-lg text-[#FF5D8F]">{{
                        $t("receiveGifts.optionSelectPanel.shareGiftBtnText")
                    }}</span>
                </button>
                <!-- divider -->
                <div class="h-[1px] w-full bg-[#DDDFEC]"></div>
                <!-- end gift -->
                <button
                    @click="retrieveGiftsOptionOnclick"
                    class="flex h-14 w-full items-center justify-center"
                >
                    <span class="text-lg text-[#000]">{{
                        $t("receiveGifts.optionSelectPanel.retrieveGiftBtnText")
                    }}</span>
                </button>
                <!-- divider -->
                <div class="h-[1px] w-full bg-[#DDDFEC]"></div>
                <!-- cancel -->
                <button
                    @click="close"
                    class="flex h-14 w-full items-center justify-center"
                >
                    <span class="text-lg text-[#000]">{{
                        $t("utils.cancel")
                    }}</span>
                </button>
            </div>
        </div>
    </transition>
</template>

<script>
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiArrowRightTop } from "@mdi/js";

export default {
    name: "OptionSelectPanel",
    components: {
        SvgIcon,
    },
    props: {
        isOptionSelectPanelActive: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({ mdiArrowRightTop }),
    methods: {
        shareOptionOnClick() {
            this.$emit("shareOptionOnClick");
        },
        close() {
            this.$emit("update:isOptionSelectPanelActive", false);
        },
        retrieveGiftsOptionOnclick() {
            this.$emit("RetrieveGiftsOptionOnclick");
        },
    },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>

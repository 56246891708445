<template>
    <div
        @click="onSelectToken(name)"
        class="flex h-[72px] w-full items-center justify-between border-b border-[#DDDFEC]"
    >
        <div class="flex items-center gap-3">
            <!-- token icon -->
            <img
                @error="
                    (e) =>
                        (e.target.src = '/assets/imgs/utils/token/default.png')
                "
                :src="`assets/imgs/utils/token/${image}`"
                alt=""
                class="h-10 w-10"
            />
            <div class="flex flex-col">
                <span class="text-base font-bold text-[#111]">{{ name }}</span>
                <span class="text-xs text-[#aaa]">{{
                    (onChainInfo && onChainInfo.chainName) || ""
                }}</span>
            </div>
        </div>
        <!-- content wrapper -->
        <div class="flex flex-col items-end">
            <span v-if="balance" class="text-base font-bold text-[#111]">{{
                balance
            }}</span>
            <slot name="3rdParty-transfer-btn"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "TokenItem",
    props: {
        image: {
            type: String,
            required: true,
            default: "default.png",
        },
        name: {
            type: String,
            required: true,
        },
        balance: String,
        onChainInfo: {
            type: Object,
            default: null,
        },
    },
    methods: {
        onSelectToken(name) {
            this.$emit("selectToken", name);
        },
    },
};
</script>

<style lang="scss" scoped></style>

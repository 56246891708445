export default {
    inserted(el, binding) {
        const options = {
            root: null,
            rootMargin: "0px",
            threshold: 1.0,
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    binding.value();
                }
            });
        }, options);

        observer.observe(el);
    },
};

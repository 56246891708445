<template>
    <div class="flex h-full w-full items-center justify-center">
        <Loading />
    </div>
</template>

<script>
import Loading from "@/components/Loading.vue";

export default {
    name: "Landing",
    components: {
        Loading,
    },
    methods: {
        checkPathAndNavigate() {
            const currentRouteName = this.$router.currentRoute.name;
            if (currentRouteName === "landing") {
                this.$router.push({ name: "wallet" });
            }
        },
    },
    created() {
        setTimeout(this.checkPathAndNavigate, 1000);
    },
};
</script>

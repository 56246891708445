import store from "@/store";
export const handleSelectToken = (tokenName) => {
    if (tokenName === store.getters.currentTokenName) return;
    const targetToken =
        store.state.tokenList &&
        store.state.tokenList.find((token) => token._id === tokenName);
    if (targetToken) {
        store.commit("setCurrentToken", targetToken);
    }
};

export const handleCopyText = async (text, callbackSuccess) => {
    if (navigator.clipboard && window.isSecureContext) {
        await navigator.clipboard
            .writeText(text)
            .then(() => {
                callbackSuccess();
            })
            .catch((err) => {
                console.error("Could not copy text: ", err);
            });
    } else {
        // Fallback method for older browsers or non-secure context
        let textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            callbackSuccess();
        } catch (err) {
            console.error("Fallback: Oops, unable to copy", err);
        }

        document.body.removeChild(textArea);
    }
};

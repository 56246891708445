import Vue from "vue";
import Antd from "ant-design-vue";
import App from "./App.vue";
import { preload } from "./utils/preload";
import router from "./router";
import store from "./store";
import "intersection-observer";
import Intersect from "@/directives/intersect";
import eruda from "eruda";
import "ant-design-vue/dist/antd.css";
import "./css/index.css";
import i18n from "./locale";
// 删除下面这些关于 TonConnectUI 的导入和代码
import { TonConnectUI } from "@tonconnect/ui";
import { Buffer } from "buffer";

window.Buffer = Buffer; // 将 Buffer 添加到全局对象中

// Initialize Telegram Analytics SDK
const initTGAnalytics = function () {
    if (
        window.telegramAnalytics &&
        window.Telegram &&
        window.Telegram.WebApp &&
        window.Telegram.WebApp.initData
    ) {
        console.log("initTGAnalytics");
        window.telegramAnalytics
            .init({
                token: "eyJhcHBfbmFtZSI6IlRvbkdpZnRzIiwiYXBwX3VybCI6Imh0dHBzLy90Lm1lL0dldFRvbkdpZnRzX0JvdCIsImFwcF9kb21haW4iOiJodHRwczovL21pbmkudG9uZ2lmdHMuYXBwLyJ9!SC5I2P7Z6XQtdWo0KNkuuLv8j5aLQwT9713PDvnjKKs=",
                appName: "TonGifts",
            })
            .catch(() => {
                setTimeout(initTGAnalytics, 1000);
            });
    } else {
        setTimeout(initTGAnalytics, 1000);
    }
};

//? 解決下滑出現空白條問題
if (window.visualViewport) {
    window.visualViewport.addEventListener("resize", () => {
        document.body.style.height = window.visualViewport.height + "px";
    });
}
// This will ensure user never overscroll the page
window.addEventListener("scroll", () => {
    if (window.scrollY > 0) window.scrollTo(0, 0);
});

if (process.env.NODE_ENV === "production") {
    setTimeout(initTGAnalytics, 1000);
}

if (process.env.NODE_ENV === "development") {
    // ...development specific code...
    eruda.init();
}

Vue.config.productionTip = false;
Vue.prototype.$bus = new Vue(); // 添加到Vue的原型，这样在任何组件中都可以使用它

// 定义全局的自定义元素
// 检查tc-root是否已经注册
// if (!customElements.get('tc-root')) {
//     // 定义全局的自定义元素
//     customElements.define('tc-root', TonConnectUI);
// }

Vue.directive("intersect", Intersect);

Vue.use(Antd);

// 这里我们不再需要全局创建 TonConnectUI 实例
// 因此下面这行代码应该被删除或注释掉
// Vue.prototype.$tonConnectUI = tonConnectUI;

preload(() => {
    console.log("All resources have been preloaded");
    new Vue({
        router,
        store,
        i18n,
        render: (h) => h(App),
    }).$mount("#app");
});

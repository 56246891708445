<template>
    <div
        class="flex h-14 w-screen items-center gap-4 bg-[#fff] px-6"
        style="box-shadow: 0px -4px 8px 0px rgba(0, 0, 0, 0.02)"
    >
        <button
            v-for="(item, index) in renderNavItems()"
            :key="index"
            @click="linkOnclick(item.name)"
            :disabled="currentRouteName === item.name"
            class="flex h-14 w-1/3 flex-col items-center justify-center gap-[2px]"
        >
            <img
                v-if="item.img"
                @error="
                    (e) =>
                        item.img.backupSrc
                            ? (e.target.src =
                                  '/assets/imgs/utils/token/default.png')
                            : undefined
                "
                :src="item.img.src"
                :alt="item.img.alt"
                :class="item.img.class"
            />
            <svg-icon
                v-else
                type="mdi"
                :path="item.icon.path"
                :size="24"
                class="h-6 text-[#9699B5]"
                :class="{
                    '!text-[#ff5d8f]': currentRouteName === item.name,
                }"
            ></svg-icon>
            <span
                class="text-[10px] leading-3 text-[#9699B5]"
                :class="{
                    'font-bold !text-[#ff5d8f]': currentRouteName === item.name,
                }"
                >{{ item.content }}</span
            >
        </button>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiDotsGrid, mdiWallet } from "@mdi/js";
import i18n from "@/locale";

export default {
    name: "Navigator",
    components: {
        SvgIcon,
    },
    data: () => ({
        mdiWallet,
        mdiDotsGrid,
    }),
    computed: {
        ...mapGetters(["currentCreditsTokenName"]),
        currentRouteName() {
            return this.$route.name;
        },
    },
    methods: {
        linkOnclick(routeName) {
            this.$router.push({ name: routeName });
        },
        renderNavItems() {
            return [
                {
                    name: "wallet",
                    img: {
                        src: "assets/imgs/utils/logo/ton_gifts_logo.svg",
                        alt: "TonGifts logo",
                        class: "m-[2px] h-[22px]",
                    },
                    content: "TonGifts",
                },
                {
                    name: "earn-credits",
                    img: {
                        src: `assets/imgs/utils/token/${this.currentCreditsTokenName}.png`,
                        alt: "Currency logo",
                        backupSrc: "/assets/imgs/utils/token/default.png",
                        class: "h-6",
                    },
                    content: `$${this.currentCreditsTokenName || "-"}`,
                },
                // {
                //     name: "discover",
                //     icon: {
                //         path: this.mdiDotsGrid,
                //     },
                //     content: i18n.t("components.navigator.discover"),
                // },
                {
                    name: "assets",
                    icon: {
                        path: this.mdiWallet,
                    },
                    content: i18n.t("components.navigator.assets"),
                },
            ];
        },
    },
};
</script>

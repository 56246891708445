<template>
    <transition name="fade">
        <div
            v-show="isWalletConnectPanelActive"
            class="fixed left-0 top-0 z-10 h-screen w-screen"
        >
            <!-- overlay -->
            <div
                @click="close"
                class="absolute left-0 top-0 z-[-1] h-full w-full bg-[#000] bg-opacity-60"
            ></div>
            <!-- panel content -->
            <div
                class="absolute bottom-0 left-0 flex w-full flex-col items-center justify-center gap-6 rounded-t-xl bg-[#FFF] p-6"
            >
                <!-- Close Button -->
                <button
                    @click="close"
                    class="absolute right-4 top-4 flex h-6 w-6 items-center justify-center rounded-full bg-[#F0F0F0] text-base text-[#fff]"
                >
                    &times;
                </button>
                <!-- 錢包尚未連接 -->
                <div
                    v-show="!currentConnectedWalletAddress"
                    class="flex w-full flex-col items-center gap-6"
                >
                    <!-- connect image -->
                    <img
                        src="assets/imgs/components/walletConnectPanel/walletConnectPanel_img.png"
                        alt=""
                        class="w-[120px]"
                    />
                    <!-- text wrapper -->
                    <div
                        class="flex w-full flex-col items-center gap-3 text-center"
                    >
                        <span
                            class="text-center text-lg font-bold text-[#111]"
                            >{{
                                $t(
                                    "receiveGifts.giftCover.tasks.walletConnectPanel.disconnectedTitleText",
                                )
                            }}</span
                        >
                        <span class="text-center text-base text-[#111]">{{
                            $t(
                                "receiveGifts.giftCover.tasks.walletConnectPanel.disconnectedDescriptionText",
                            )
                        }}</span>
                    </div>
                </div>
                <!-- 錢包已經連接 -->
                <div
                    v-show="currentConnectedWalletAddress"
                    class="flex w-full flex-col items-center gap-6"
                >
                    <!-- connected title -->
                    <span class="text-center text-lg font-bold text-[#111]">{{
                        $t(
                            "receiveGifts.giftCover.tasks.walletConnectPanel.connectedTitleText",
                        )
                    }}</span>
                    <!-- wallet action wrapper -->
                    <div class="flex w-full flex-col items-center gap-3">
                        <!-- connected wallet address -->
                        <button
                            @click="disconnectWallet"
                            class="flex h-14 w-full items-center gap-2 rounded-lg bg-[#f0f0f0] px-6"
                        >
                            <img
                                src="assets/imgs/utils/token/TON.png"
                                alt=""
                                class="h-7 w-7 min-w-7"
                            />
                            <span
                                class="flex-grow truncate text-sm font-bold text-[#111]"
                            >
                                {{ currentConnectedWalletAddress }}
                            </span>
                            <svg-icon
                                class="h-4 min-h-6 w-4 min-w-6 text-center text-2xl text-[#0088CC]"
                                type="mdi"
                                :path="mdiSwapHorizontal"
                            >
                            </svg-icon>
                        </button>
                        <!-- disconnect button -->
                        <!-- <button
                            @click="disconnectWallet"
                            class="flex h-7 items-center gap-1"
                        >
                            <svg-icon
                                class="h-4 w-4 text-center text-base text-[#0088CC]"
                                type="mdi"
                                :path="mdiSwapHorizontal"
                            >
                            </svg-icon>
                            <span class="text-sm text-[#0088CC]">{{
                                $t(
                                    "receiveGifts.giftCover.tasks.walletConnectPanel.disconnectBtnText",
                                )
                            }}</span>
                        </button> -->
                    </div>
                </div>
                <WalletConnectButton
                    v-show="!currentConnectedWalletAddress"
                    ref="walletConnectButton"
                    :current-token-name="currentTokenName"
                    @onFunctionAvailable="isAvailable = true"
                ></WalletConnectButton>
            </div>
        </div>
    </transition>
</template>

<script>
import { mapGetters } from "vuex";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiSwapHorizontal } from "@mdi/js";
import taskApi from "@/apis/task";
import WalletConnectButton from "@/components/walletConnector/components/WalletConnectButton.vue";

export default {
    name: "WalletConnectPanel",
    components: {
        SvgIcon,
        WalletConnectButton,
    },
    props: {
        isWalletConnectPanelActive: {
            type: Boolean,
            default: false,
        },
        // connectedWalletAddress: {
        //     type: String,
        //     default: null,
        // },
        // shouldConnect: {
        //     type: Boolean,
        //     default: false,
        // },
        // shouldCheckIn: {
        //     type: Boolean,
        //     default: false,
        // },
        // shouldDailyCheckIn: {
        //     type: Boolean,
        //     default: false,
        // },
    },
    data: () => ({
        mdiSwapHorizontal,
        isAvailable: false,
        targetAddress: "EQAutMVU3M9MY6SPTRHG74VdJlpY-B3HVttCPY2dd8BOrxME", // 我们的SMART CONTRACT地址
    }),
    computed: {
        ...mapGetters(["currentTokenName", "currentConnectedWalletAddress"]),
    },
    // watch: {
    //     shouldConnect() {
    //         console.log("should connect");
    //         this.$refs.walletConnectButton.connectToWallet();
    //     },
    //     shouldCheckIn() {
    //         this.checkIn();
    //     },
    //     shouldDailyCheckIn() {
    //         this.dailyCheckIn();
    //     },
    // },
    methods: {
        close() {
            this.$emit("update:isWalletConnectPanelActive", false);
        },
        // async checkWalletConnection() {
        //     console.log(
        //         "this.$refs.walletConnectButton:",
        //         this.$refs.walletConnectButton,
        //     );
        //     if (this.$refs.walletConnectButton) {
        //         try {
        //             let connectedWalletAddress =
        //                 this.$refs.walletConnectButton.getWalletAddress();
        //             console.log(
        //                 "connectedWalletAddress:",
        //                 connectedWalletAddress,
        //             );
        //             if (connectedWalletAddress) {
        //                 this.$emit("onWalletConnected", connectedWalletAddress);
        //             }
        //             let res = await taskApi.getContractAction("CheckIn");
        //             if (res.status === 200) this.memo = res.data;
        //         } catch (err) {
        //             console.log("deposit err: ", err);
        //         }
        //     }
        // },
        // handleWalletConnected(address) {
        //     console.log("wallet connected, address: ", address);
        //     this.$emit("onWalletConnected", address);
        // },
        // handleWalletDisconnected() {
        //     console.log("wallet disconnected");
        //     this.$emit("onWalletDisconnected");
        // },
        connectWallet(cb) {
            if (!this.isAvailable) {
                if (cb) {
                    cb("not available yet");
                }
                return;
            }
            this.$refs.walletConnectButton.connectToWallet(cb);
        },
        disconnectWallet(cb) {
            if (!this.isAvailable) {
                if (cb) {
                    cb("not available yet");
                }
                return;
            }
            console.log(
                "this.$refs.walletConnectButton:",
                this.$refs.walletConnectButton,
            );
            this.$nextTick(() => {
                if (this.$refs.walletConnectButton) {
                    console.log("start disconnect");
                    this.$refs.walletConnectButton.disconnect((err) => {
                        console.log(err);
                    });
                }
            });
        },
        // 紅包任務簽到
        async checkIn(cb) {
            if (!this.isAvailable) {
                if (cb) {
                    cb("not available yet");
                }
                return;
            }
            try {
                const res = await taskApi.getContractAction("CheckIn");
                if (res.status !== 200) {
                    throw new Error(res);
                }
                const memo = res.data;

                // 构建交易对象
                const transaction = {
                    amount: "0.00001", // 转换为字符串传递给 API
                    token: "TON",
                    targetAddress: this.targetAddress,
                    bounceable: true,
                    memo,
                };
                this.$refs.walletConnectButton.initiatePayment(transaction, cb);
            } catch (err) {
                console.log("checkin error", err);
                if (cb) {
                    cb(err);
                }
            }
        },
        // 每日簽到
        async dailyCheckIn(cb) {
            if (!this.isAvailable) {
                if (cb) {
                    cb("not available yet");
                }
                return;
            }
            try {
                const res = await taskApi.getContractAction("DailyCheckIn");
                if (res.status !== 200) throw new Error(res);
                const memo = res.data;
                // 构建交易对象
                const transaction = {
                    amount: "0.00001", // 转换为字符串传递给 API
                    token: "TON",
                    targetAddress: this.targetAddress,
                    bounceable: true,
                    memo,
                };
                this.$refs.walletConnectButton.initiatePayment(transaction, cb);
            } catch (err) {
                console.log("checkin error", err);
                if (cb) {
                    cb(err);
                }
            }
        },
        async deposit(message, cb) {
            if (!this.isAvailable) {
                if (cb) {
                    cb("not available yet");
                }
                return;
            }
            try {
                const res = await task.getContractAction("Deposit");
                if (res.status !== 200) throw new Error(res);
                const memo = res.data;
                const transaction = {
                    ...message,
                    memo,
                };
                this.$refs.walletConnectButton.initiatePayment(transaction, cb);
            } catch (err) {
                console.log("deposit error", err);
                if (cb) {
                    cb(err);
                }
            }
        },
        async deposit(message, cb) {
            if (!this.isAvailable) {
                if (cb) {
                    cb("not available yet");
                }
                return;
            }
            try {
                const res = await task.getContractAction("Withdraw");
                if (res.status !== 200) throw new Error(res);
                const memo = res.data;
                const transaction = {
                    ...message,
                    memo,
                };
                this.$refs.walletConnectButton.initiatePayment(transaction, cb);
            } catch (err) {
                console.log("withdraw error", err);
                if (cb) {
                    cb(err);
                }
            }
        },
        // handleDepositSuccess(amount) {
        //     console.log("Checkin success, amount: ", amount);
        //     this.$emit("onCheckinSuccess", amount);
        // },
        // handleCheckinErr(error) {
        //     console.log("Checkin Error:", error);
        //     this.$emit("onCheckinError", error);
        // },
    },
};
</script>

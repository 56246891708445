export default {
    tokenTabs: [
        {
            key: "all",
            name: "All",
        },
        {
            key: "token",
            name: "Tokens",
        },
        {
            key: "point",
            name: "Points",
        },
        {
            key: "collect",
            name: "Collect",
        },
    ],
};

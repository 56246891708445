import { apiHelper } from "@/utils/requestHelper.js";

export default {
    getUserInfo() {
        return apiHelper.get("/user/login");
    },
    getThirdPartyOrder(orderId) {
        return apiHelper.get(`/user/thirdParty/order?id=${orderId}`);
    },
    confirmThirdPartyOrder({ id }) {
        return apiHelper.post("/user/thirdParty/order", { id });
    },
    getCheckinConfig() {
        return apiHelper.get("/daily/config");
    },
    getCheckinProgress() {
        return apiHelper.get("/daily/record");
    },
    checkin({ type }) {
        return apiHelper.post("/daily/checkin", { type });
    },
    getUserInviteCode() {
        return apiHelper.get("/user/invidecode");
    },
    bindUserInviteCode({ inviteCode }) {
        return apiHelper.post("/user/invite", { inviteCode });
    },
    getUserReferrals() {
        return apiHelper.get("/user/referrals");
    },
    event({ eventType, eventData }) {
        return apiHelper.post("/user/event", { eventType, eventData });
    },
};

<template>
    <div class="flex w-full flex-col items-center gap-4">
        <div class="flex w-full items-center justify-between">
            <span class="text-base font-bold text-[#111111]">{{
                $t("utils.app")
            }}</span>
            <button @click="$router.push({ name: 'category' })">
                <svg-icon
                    type="mdi"
                    :path="mdiFormatListBulleted"
                    :size="24"
                    class="text-[#111111]"
                ></svg-icon>
            </button>
        </div>
        <!-- Slide -->
        <Carousel
            v-if="renderAppSlides().length"
            class="app-carousel"
            :carouselSettings="carouselSettings"
            :class-name="renderAppSlides().length > 1 ? 'pl-6' : 'pl-4'"
        >
            <AppSlide
                v-for="(item, index) in renderAppSlides()"
                :key="index"
                :logo="item.logo"
                :title="item.title"
                :description="item.description"
                :detailLink="item.detailLink"
                :miniappLink="item.miniappLink"
            />
        </Carousel>
    </div>
</template>

<script>
import SvgIcon from "@jamescoyle/vue-icon";

import { mdiFormatListBulleted } from "@mdi/js";
import Carousel from "@/components/Carousel.vue";
import AppSlide from "./AppSlide.vue";

export default {
    name: "DiscoverApp",
    components: {
        SvgIcon,
        Carousel,
        AppSlide,
    },
    data: () => ({
        mdiFormatListBulleted,

        carouselSettings: {
            slidesToShow: 1.05,
        },
    }),
    methods: {
        renderAppSlides() {
            return [
                {
                    logo: "assets/imgs/utils/logo/ton_gifts_logo.svg",
                    title: "TonGifts",
                    description:
                        "Gifts to everyone zxczxczxzxvzxvsdfsdgsdg zxczxczxzxvzxvsdfsdgsdg zxczxczxzxvzxvsdfsdgsdg",
                    detailLink: "/detail",
                    miniappLink: "#",
                },
                {
                    logo: "assets/imgs/utils/logo/ton_gifts_logo.svg",
                    title: "TonGifts",
                    description: "Gifts to everyone",
                    detailLink: "/detail",
                    miniappLink: "#",
                },
                {
                    logo: "assets/imgs/utils/logo/ton_gifts_logo.svg",
                    title: "TonGifts",
                    description: "Gifts to everyone",
                    detailLink: "/detail",
                    miniappLink: "#",
                },
                {
                    logo: "assets/imgs/utils/logo/ton_gifts_logo.svg",
                    title: "TonGifts",
                    description: "Gifts to everyone",
                    detailLink: "/detail",
                    miniappLink: "#",
                },
            ];
        },
    },
};
</script>

<style lang="scss">
.app-carousel {
    .slick-slide {
        padding-right: 12px;
    }
}
</style>

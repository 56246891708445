<template>
    <div class="fixed left-0 top-0 z-10 h-full w-full bg-[#fff] px-6 py-4">
        <!-- content wrapper -->
        <div v-if="!isLoading && orderInfo">
            <!-- confirm order -->
            <OrderPending
                v-if="orderStatus === 1 && !isOrderFailed"
                @confirmOrderOnClick="confirmOrder"
                :thirdPartyName="thirdPartyName"
                :orderId="orderId"
                :orderTokenName="orderTokenName"
                :orderAmount="orderAmount"
                :isConfirming="isConfirming"
            />
            <OrderSucceed
                v-if="orderStatus === 2 && !isOrderFailed"
                :thirdPartyName="thirdPartyName"
                :orderId="orderId"
                :orderTokenName="orderTokenName"
                :orderAmount="orderAmount"
                :goThirdPartyUrl="goThirdPartyUrl"
                :isConfirming="isConfirming"
            />
            <OrderFailed
                v-if="isOrderFailed"
                @confirmOrderOnClick="confirmOrder"
                :thirdPartyName="thirdPartyName"
                :orderId="orderId"
                :isConfirming="isConfirming"
            />
        </div>
        <!-- loading -->
        <Loading v-else :fullScreen="true" />
    </div>
</template>

<script>
import userAPI from "@/apis/user";
import OrderPending from "./components/OrderPending";
import OrderSucceed from "./components/OrderSucceed";
import OrderFailed from "./components/OrderFailed";
import Loading from "@/components/Loading.vue";

export default {
    name: "ThirdPartyOrder",
    components: {
        OrderPending,
        OrderSucceed,
        OrderFailed,
        Loading,
    },
    data: () => ({
        isLoading: false,
        isConfirming: false,
        orderInfo: null,
        isOrderFailed: false,
    }),
    computed: {
        orderUserId() {
            return this.orderInfo && this.orderInfo.userId;
        },
        thirdPartyName() {
            return (
                this.orderInfo &&
                this.orderInfo.thirdParty &&
                this.orderInfo.thirdParty.name
            );
        },
        orderId() {
            return this.orderInfo && this.orderInfo._id;
        },
        orderTokenName() {
            return this.orderInfo && this.orderInfo.token;
        },
        orderAmount() {
            return (this.orderInfo && this.orderInfo.amount) || "0";
        },
        orderStatus() {
            return this.orderInfo && this.orderInfo.status; // 1: 未完成, 2: 已完成
        },
        goThirdPartyUrl() {
            return this.orderInfo && this.orderInfo.jumpUrl;
        },
    },
    methods: {
        async getThirdPartyOrderInfo(orderId) {
            this.isLoading = true;
            try {
                const res = await userAPI.getThirdPartyOrder(orderId);
                console.log("getThirdPartyOrderInfo res: ", res);
                if (res.status !== 200) {
                    throw new Error(res);
                } else {
                    this.orderInfo = res.data;
                }
            } catch (err) {
                console.log("getThirdPartyOrderInfo err: ", err);
            }
            this.isLoading = false;
        },
        async confirmOrder() {
            this.isConfirming = true;
            try {
                const res = await userAPI.confirmThirdPartyOrder({
                    id: this.orderId,
                });
                console.log("confirmOrder res: ", res);
                if (res.status !== 200) {
                    throw new Error(res);
                } else {
                    this.orderInfo = res.data;
                }
            } catch (err) {
                console.log("confirmOrder err: ", err);
                this.isOrderFailed = true;
            }
            this.isConfirming = false;
        },
    },
    created() {
        const orderId = this.$route.query.id;
        console.log("orderId: ", orderId);
        if (orderId) {
            this.getThirdPartyOrderInfo(orderId);
        }
    },
};
</script>

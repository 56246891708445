<template>
    <button
        @click="promotionBannerOnClick"
        class="relative z-10 flex h-[126px] min-h-[126px] w-full items-center overflow-hidden rounded-2xl"
    >
        <img
            v-if="promotionBannerInfo"
            :src="bannerImg"
            alt=""
            class="h-full w-full object-cover object-center"
        />
        <div v-else class="h-full w-full animate-pulse bg-[#fff]"></div>
    </button>
</template>

<script>
import envelopeApi from "@/apis/envelope";

export default {
    name: "PromotionBanner",
    data: () => ({
        promotionBannerInfo: null,
    }),
    computed: {
        bannerImg() {
            return this.promotionBannerInfo && this.promotionBannerInfo.pic;
        },
        targetGiftId() {
            return (
                this.promotionBannerInfo && this.promotionBannerInfo.redEnvelope
            );
        },
        targetLink() {
            return this.promotionBannerInfo && this.promotionBannerInfo.link;
        },
    },
    methods: {
        async getPromotionBanner() {
            try {
                const res = await envelopeApi.getBanner();
                console.log("getPromotionBanner res: ", res);
                if (res.status !== 200) {
                    throw new Error();
                } else {
                    const info = res.data && res.data[0];
                    this.promotionBannerInfo = info;
                }
            } catch (err) {
                console.log("getPromotionBanner err: ", err);
            }
        },
        promotionBannerOnClick() {
            // 如果有 指定禮物id 就跳轉開禮物
            if (this.targetGiftId) {
                this.$router.push({
                    name: "receive-gifts",
                    query: { id: this.targetGiftId },
                });
                return;
            }
            // 如果沒有 指定禮物id，有 link 就直接跳 link
            if (this.targetLink) {
                window.open(this.targetLink);
                return;
            }
        },
    },
    created() {
        this.getPromotionBanner();
    },
};
</script>

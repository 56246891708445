import { apiHelper } from "@/utils/requestHelper.js";

export default {
    createTasks({ tasks }) {
        return apiHelper.post("/task/create", { tasks });
    },

    updateTask({ taskId, status }) {
        return apiHelper.post("/task/update", { taskId, status });
    },

    // 新增获取Twitter登录URL的API
    getTwitterAuthUrl(id, target, url) {
        return apiHelper.get(
            `/task/auth/twitter?id=${id}&target=${target}&url=${url}`,
        );
    },

    tonConnect(address) {
        return apiHelper.get(`/task/tonConnect?address=${address}`);
    },

    getContractAction(action) {
        return apiHelper.get(`/task/getContractAction?action=${action}`);
    },
    // 獲取積分任務列表
    getCreditsTaskList() {
        return apiHelper.get("/task/getTaskList");
    },
    // 確認單個任務完成狀態
    checkTask(payload) {
        return apiHelper.post("/task/checkTask", payload);
    },
};

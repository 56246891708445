<template>
    <a-drawer
        class="recommended-box"
        title="Essential for web3"
        placement="bottom"
        :visible="visible"
        @close="hide"
    >
        <p class="text-xs text-[#aaaaaa]">
            Ton Heroes is a leading RPG game on TON! Free to Play, Invite to
            earn more ⭐️Hero points for fops! Act now! Ton Heroes is a leading
            RPG game on TON! Free to Play, Invite to earn for future rdrops! Act
            now! Ton Heroes is a leading RPG game on TON! Free to Play, Invite
            to earn mo points for future 💰airdrops!
            <br />
            Act now!
        </p>
        <div class="flex flex-col gap-4 pt-4">
            <EssentialItem
                v-if="renderItems().length"
                v-for="(item, index) in renderItems()"
                :key="index"
                :logo="item.logo"
                :appName="item.appName"
                :keyword="item.keyword"
                :detailLink="item.detailLink"
                :rewards="item.rewards"
                :miniappLink="item.miniappLink"
            />
        </div>
    </a-drawer>
</template>

<script>
import EssentialItem from "./EssentialItem.vue";

export default {
    name: "RecommendedBox",
    components: {
        EssentialItem,
    },
    data: () => ({
        visible: false,
    }),
    methods: {
        show() {
            this.visible = true;
        },
        hide() {
            this.visible = false;
        },
        renderItems() {
            return Array.from({ length: 10 }, () => ({
                logo: "assets/imgs/utils/covers/cover_newdefault.png",
                appName: "TonGifts",
                keyword: "miniapp keyword",
                detailLink: "/detail",
                miniappLink: "#",
                rewards: [
                    {
                        img: "HIT.png",
                        amount: "300",
                        token: "HIT",
                    },
                    {
                        img: "GFT.png",
                        amount: "300",
                        token: "GFT",
                    },
                ],
            }));
        },
    },
};
</script>

<style lang="scss">
.recommended-box {
    .ant-drawer {
        &-content {
            border-top-left-radius: 1rem;
            border-top-right-radius: 1rem;
            &-wrapper {
                height: 90vh !important;
            }
        }

        &-header {
            border-bottom: none;
            padding-top: 24px;
            padding-bottom: 0;

            .anticon {
                background-color: #f0f0f0;
                width: 28px;
                height: 28px;
                vertical-align: middle;
                border-radius: 50%;
                line-height: 1.5;

                & > svg {
                    color: #fff;
                }
            }
        }

        &-title {
            color: #111;
            font-size: 18px;
            font-weight: 700;
        }

        &-body {
            padding-top: 12px;
        }
    }
}
</style>

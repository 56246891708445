<template>
    <div class="flex items-center justify-between">
        <div class="flex items-center gap-3">
            <!-- Logo -->
            <img
                @click="$router.push(detailLink)"
                @error="
                    (e) =>
                        (e.target.src = '/assets/imgs/utils/token/default.png')
                "
                class="size-14 rounded-lg hover:cursor-pointer"
                :src="logo"
            />

            <div class="">
                <span
                    @click="$router.push(detailLink)"
                    class="text-xs font-bold text-[#111111] hover:cursor-pointer"
                    >{{ appName }}</span
                >
                <div
                    @click="$router.push(detailLink)"
                    class="max-w-36 truncate py-0.5 text-[10px] text-[#aaaaaa] hover:cursor-pointer"
                >
                    {{ keyword }}
                </div>
                <div class="flex items-center gap-1">
                    <div
                        v-for="(token, i) in rewards"
                        :key="i"
                        class="flex items-center gap-0.5"
                    >
                        <img
                            @error="
                                (e) =>
                                    (e.target.src =
                                        '/assets/imgs/utils/token/default.png')
                            "
                            :src="`assets/imgs/utils/token/${token.img}`"
                            alt=""
                            class="size-3"
                        />
                        <span class="text-xs font-bold text-[#ffc90b]">{{
                            token.amount + token.token
                        }}</span>
                    </div>
                </div>
            </div>
        </div>
        <!-- Button -->
        <button
            @click="$router.push(miniappLink)"
            class="h-8 basis-1/5 rounded-lg bg-[#ff5c8e] px-4 text-sm font-bold leading-[1.875rem] text-white"
        >
            {{ $t("utils.open") }}
        </button>
    </div>
</template>

<script>
export default {
    name: "EssentialItem",
    props: {
        logo: String,
        appName: {
            type: String,
            required: true,
        },
        keyword: String,
        detailLink: {
            type: String,
            required: true,
        },
        miniappLink: {
            type: String,
            required: true,
        },
        rewards: {
            type: Array,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped></style>

import Vue from "vue";
import VueI18n from "vue-i18n";
import en from "./en.json";
import zh from "./zh.json";
import ru from "./ru.json";
import id from "./id.json";
import ko from "./ko.json";
import vi from "./vi.json";

Vue.use(VueI18n);

const TGLanguageCode =
    window.Telegram &&
    window.Telegram.WebApp &&
    window.Telegram.WebApp.initDataUnsafe &&
    window.Telegram.WebApp.initDataUnsafe.user &&
    window.Telegram.WebApp.initDataUnsafe.user.language_code;
const TGLang = TGLanguageCode ? TGLanguageCode.split("-")[0] : null;
const localLang = localStorage.getItem("lang");

const i18n = new VueI18n({
    locale: localLang || TGLang || "en",
    // locale: "en",
    fallbackLocale: "en",
    messages: {
        en,
        zh,
        ru,
        // id,
        // ko,
        // vi,
    },
});

export default i18n;

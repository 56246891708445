var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex min-h-14 items-center gap-3 rounded-2xl bg-[#fff] px-4 py-3",on:{"click":_vm.taskBtnOnClick}},[_c('img',{staticClass:"h-10 w-10 rounded-lg object-contain",attrs:{"src":_vm.taskImgPath,"alt":""},on:{"error":(e) => (e.target.src = `/assets/imgs/utils/token/GFT.png`)}}),_c('div',{staticClass:"flex flex-grow flex-col gap-1"},[_c('span',{staticClass:"text-sm font-bold leading-4 text-[#111]"},[_vm._v(_vm._s(_vm.taskName))]),_c('div',{staticClass:"flex items-center gap-1"},[_c('img',{staticClass:"h-4 w-4",attrs:{"src":`assets/imgs/utils/token/${_vm.currentCreditsTokenName}.png`,"alt":"credits_point"},on:{"error":(e) =>
                        (e.target.src =
                            '/assets/imgs/utils/token/default.png')}}),_c('span',{staticClass:"text-xs text-[#FFC90C]"},[_vm._v(_vm._s(`+${_vm.task.amount}`))])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.task.status === 2 || _vm.successTasks.includes(_vm.task._id)),expression:"task.status === 2 || successTasks.includes(task._id)"}],staticClass:"flex h-6 w-6 items-center justify-center rounded-full bg-[#E3FFE9]"},[_c('svg-icon',{staticClass:"text-[#00DD31]",attrs:{"type":"mdi","path":_vm.mdiCheck,"size":20}})],1),_c('button',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.task.status === 0 &&
            !_vm.pendingTasks.includes(_vm.task._id) &&
            !_vm.successTasks.includes(_vm.task._id)
        ),expression:"\n            task.status === 0 &&\n            !pendingTasks.includes(task._id) &&\n            !successTasks.includes(task._id)\n        "}],staticClass:"tex-center flex h-8 min-w-20 items-center justify-center rounded-lg bg-[#FFC90C] px-4 text-sm font-bold text-[#fff]"},[_vm._v(" "+_vm._s(_vm.taskBtnText)+" ")]),_c('button',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.task.status === 0 &&
            _vm.pendingTasks.includes(_vm.task._id) &&
            !_vm.successTasks.includes(_vm.task._id)
        ),expression:"\n            task.status === 0 &&\n            pendingTasks.includes(task._id) &&\n            !successTasks.includes(task._id)\n        "}],staticClass:"h-6 w-6",attrs:{"disabled":_vm.checkingTasks.includes(_vm.task._id)},on:{"click":function($event){$event.stopPropagation();return _vm.refreshBtnOnClick(_vm.task)}}},[_c('svg-icon',{staticClass:"text-xl text-[#9699B5]",class:{
                '!animate-spin': _vm.checkingTasks.includes(_vm.task._id),
            },attrs:{"type":"mdi","path":_vm.mdiRefresh,"size":20}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
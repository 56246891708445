import Vue from "vue";
import Vuex from "vuex";
import userAPI from "@/apis/user";
import envelopeAPI from "@/apis/envelope";
import localGifts from "@/json/gifts.json";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        userInfo: null,
        currentConnectedWalletAddress: "",
        currentToken: null,
        tokenList: [],
        launchMethod: "BOT", // BOT or Miniapp
        gifts: [], //Gifts Config
    },
    getters: {
        userInfo: (state) => state.userInfo,
        currentConnectedWalletAddress: (state) =>
            state.currentConnectedWalletAddress,
        launchMethod: (state) => state.launchMethod,
        currentToken: (state) => state.currentToken,
        currentTokenName: (state) =>
            state && state.currentToken && state.currentToken._id
                ? state.currentToken._id
                : localStorage.getItem("token"),
        tokenList: (state) => state.tokenList,
        gifts: (state) => state.gifts,
        currentBalance: (state) => {
            const currentTokenName =
                (state.currentToken && state.currentToken._id) ||
                localStorage.getItem("token");
            return state.userInfo &&
                state.userInfo.balance &&
                state.userInfo.balance[currentTokenName]
                ? Number(state.userInfo.balance[currentTokenName].balance)
                : 0;
        },
        currentCreditsTokenName: (state) => {
            const creditTokens =
                state.tokenList &&
                state.tokenList.filter((token) => token.isCredits);
            const targetTokenName = creditTokens[0] && creditTokens[0]._id;
            return targetTokenName || "";
        },
        currentCreditsBalance: (state) => {
            const creditTokens =
                state.tokenList &&
                state.tokenList.filter((token) => token.isCredits);
            const targetTokenName = creditTokens[0] && creditTokens[0]._id;
            if (targetTokenName) {
                return state.userInfo &&
                    state.userInfo.balance &&
                    state.userInfo.balance[targetTokenName]
                    ? Number(state.userInfo.balance[targetTokenName].balance)
                    : 0;
            } else {
                return 0;
            }
        },
    },
    mutations: {
        // 登入
        setUserInfo(state, userInfo) {
            console.log("set user info");
            state.userInfo = {
                ...state.userIfo,
                ...userInfo,
            };
        },
        // 連接錢包成功
        setCurrentConnectedWalletAddress(state, walletAddress) {
            console.log("set currentConnectedWalletAddress");
            state.currentConnectedWalletAddress = walletAddress;
        },
        // openlink: MINIAPP;  bot:BOT
        setLaunchMethod(state, _launchMethod) {
            console.log("set launchMethod");
            state.launchMethod = _launchMethod;
        },
        // 切換幣種
        setCurrentToken(state, token) {
            state.currentToken = { ...token };
            localStorage.setItem("currentToken", token._id); // set token name
        },
        // 置入 token 列表資料
        setTokenList(state, tokenList) {
            state.tokenList = [...tokenList];
        },

        // 置入 token 列表資料
        setGifts(state, gifts) {
            state.gifts = [...gifts];
        },
    },
    actions: {
        async updateInitDataUserInfo({ commit, state }) {
            try {
                const initDataUnsafe = Telegram.WebApp.initDataUnsafe; //await userAPI.getUserInfo();
                // if (res.status !== 200) {
                //     const errMessage = res.data;
                //     throw new Error(errMessage);
                // }
                const userInfo = {
                    balance: {},
                    user: {
                        _id: `${initDataUnsafe && initDataUnsafe.user && initDataUnsafe.user.id}`,
                        username:
                            (initDataUnsafe &&
                                initDataUnsafe.user &&
                                initDataUnsafe.user.username) ||
                            "",
                        tg_info: initDataUnsafe && initDataUnsafe.user,
                    },
                };
                console.log(
                    "updateInitDataUserInfo initDataUnsafe: ",
                    initDataUnsafe,
                );
                commit("setUserInfo", userInfo);
                return null;
            } catch (err) {
                console.log("updateInitDataUserInfo err: ", err);
                return err;
            }
        },
        async updateUserInfo({ commit, state }) {
            try {
                const [userInfoRes, inviteLinkRes, referralRes] =
                    await Promise.all([
                        userAPI.getUserInfo(),
                        envelopeAPI.getInvitationGift(),
                        userAPI.getUserReferrals(),
                    ]);
                if (
                    userInfoRes.status !== 200 ||
                    inviteLinkRes.status !== 200 ||
                    referralRes.status !== 200
                ) {
                    const errMessage =
                        userInfoRes.statusText ||
                        inviteLinkRes.statusText ||
                        referralRes.statusText ||
                        "Failed to fetch user data";
                    throw new Error(errMessage);
                }
                userInfoRes.data.user["inviteLink"] = inviteLinkRes.data;
                userInfoRes.data.user["referrals"] = referralRes.data;
                commit("setUserInfo", userInfoRes.data);
                return null;
            } catch (err) {
                console.error("updateUserInfo err: ", err);
                return err;
            }
        },

        async updateGifts({ commit, state }) {
            try {
                const res = await envelopeAPI.getGifts();
                if (res.status !== 200) {
                    const errMessage = res.data;
                    throw new Error(errMessage);
                }
                const gifts = res.data;
                commit("setGifts", gifts);
                return null;
            } catch (err) {
                console.log("setGifts err: ", err);
                // 請求獲取不到時使用本地端的靜態資源
                const gifts = localGifts;
                commit("setGifts", gifts);
                return err;
            }
        },
    },
    modules: {},
});
